import React, { useEffect, useRef, useState } from "react";
import "../styles/login.scss";

// import VisibleSvg from "../components/assets/svg/visible.svg";
// import LoginImg from "../components/assets/svg/logoImg.svg";
import Logo from "../components/Logo/Logo";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, ResetPassword } from "../actions/UserLoginActions";
import Notification from "../components/Notification";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
const Login = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector(state=> state.userLogin)
  const passwordRef = useRef();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false)
//   const [forget, setForget] = useState(false);
  // const [ForgetEmail, setForgetEmail] = useState("");
 

  const {token} = useParams()
  const history = useHistory()
  useEffect(()=>{
      console.log(token)
if(!token){
    history.push("/login")
}
    if(userLogin.error){
    //   setEmail("")
      setPassword("")
    }

  }, [userLogin.error])

//   const changeVisibleHandler = () => {
//     const type = passwordRef.current.type;
//     if (type === "password") {
//       passwordRef.current.type = "text";
//     } else {
//       passwordRef.current.type = "password";
//     }
//   };

//   const handleSubmit = (e) =>{
//       e.preventDefault()
//       if(email.trim() && passwrod.trim()){
//         dispatch(login(email, passwrod))
//       }
     
//   }

  const handleResetPasswrd = (e) =>{
      if(password !== confirmPassword){
          setError("Confirm password and password should be same.")
          setTimeout(() => {
              setError("")
          }, 10000);
      }
    e.preventDefault()
    const postData = {
        password,
        confirm_password: confirmPassword
    }
    if(password.trim() && confirmPassword.trim()){
      if(password === confirmPassword){
        dispatch(ResetPassword(postData,token,userRedirect, setBtnDisabled))
      }
     
     
    }
  }

  const userRedirect = () =>{

    
    history.push("/")
    setBtnDisabled(false)

  }

  const closeError = ()=>{
      setError("")
    //   dispatch(logout())
  }
  return (
    <div className="login">
       <Notification/>
      <div className="login-logo">
      <Logo />
      </div>
      <div className="login-page">
        
        <div className="login-page-container">
        <div className="login-page-container-error">
            {error && <span className="login-page-container-error-container">{error} <span className="login-page-container-error-container-close" onClick={closeError}>+</span> </span>}
        </div>
         <form className="login-page-container-form" onSubmit={handleResetPasswrd}>
            <div className="login-page-container-form-text">
              <h3 className="login-page-container-form-text-head">
                Reset Your Password
              </h3>
              <span className="login-page-container-form-text-text">
              Reset your I-Dia chatbot Password
              </span>
            </div>
            <div className="login-page-container-form-input">
              <div className="login-page-container-form-input-password">
                <input
                  type="password"
                //   ref={passwordRef}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                />
                {/* <img
                  src={VisibleSvg}
                  onClick={changeVisibleHandler}
                  alt="show passowrd"
                /> */}
              </div>

              <div className="login-page-container-form-input-password">
                <input
                  type="password"
                  ref={passwordRef}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  required
                />
               
              </div>
            </div>
            <div className="login-page-container-form-bottom">
              <button className="login-page-container-form-bottom-submit" disabled={btnDisabled}>
                RESET PASSWORD
              </button>
            </div>
          </form> 
          
        </div>
      </div>
    </div>
  );
};

export default Login;
