import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetWorkflowContext } from '../actions/WorkflowActions';
import Sortable from '../components/sortable-tree/sortableComponet/Sortable';

const Workflow = () => {
    const dispatch = useDispatch();
    const {question} = useSelector(state=>state.workflow)
    useEffect(()=>{
        dispatch(GetWorkflowContext())
        
    }, [])
  return (

    <div>
      { console.log(question)}
        {question && <Sortable question={question}/>}
    </div>
  )
}

export default Workflow