import React from 'react'
import { Redirect } from 'react-router-dom'
import { Switch } from 'react-router-dom'
import { Route } from 'react-router-dom'
import Header from '../components/Header'
import Notification from '../components/Notification'
import "../styles/Home.scss"
import AddNewUser from './AddNewUser'
import Dashboard from './Dashboard'
import DeletedLogs from './DeletedLogs'
import HomePage from './HomePage'
import Workflow from './Workflow'

const Home = () => {

  return (
    <div className='home'>
      <Header/>
      <Switch>
          <Route exact path="/home">
            <HomePage/>
            </Route>
          <Route exact path="/dashboard">
            <Dashboard/>
            </Route>
          <Route exact path="/workflow">
            <Workflow/>
          </Route>
          <Route exact path="/newuser">
            <AddNewUser/>
          </Route>
          <Route exact path="/deletelogs">
            <DeletedLogs/>
          </Route>
          <Redirect to='/home'/>
      </Switch>
          <Notification/>
    </div>
  )
}

export default Home