import {combineReducers} from "redux";
import { CreateWorkflowContext } from "../actions/WorkflowActions";
import { AnalyticsReducers } from "./AnalyticsReducer";
import { AllUserReducers, ConversationsReducer, FrequentlyReducers, RatingsContext, SatisfactionReducer, UnttagedReducers, userFeedback } from "./DashboardReducer";
import { DeletedLogsReducer } from "./DeletedLogsReducer";
import {LoginReducers, NewUserReducers} from "./LoginReducers";
import { NotificationReducers } from "./NotificationReducers";
import { UserLogsReducers } from "./UserLogsReducer";
import { UpdateWorkflowReducer, WorkflowEditReducers, WorkflowReducers } from "./WorkflowReducers";


const rootReducer = combineReducers({
    userLogin: LoginReducers,
    newUserInfo: NewUserReducers,
    workflow: WorkflowReducers,
    updateWorkflow: UpdateWorkflowReducer,
    createWorkflow: CreateWorkflowContext,
    analytics: AnalyticsReducers,
    userLogs: UserLogsReducers,
    deleteLogs: DeletedLogsReducer,
    untaggedQuestion: UnttagedReducers,
    frequentlyQuestion: FrequentlyReducers,
    getallUser: AllUserReducers,
    getallRatings: RatingsContext,
    persentageContext: SatisfactionReducer,
    EditWorkflow: WorkflowEditReducers,
    Notification: NotificationReducers,
    userFeedback: userFeedback,
    conversations: ConversationsReducer
})

export default rootReducer