import React, { Component } from "react";
import SortableTree, {
  toggleExpandedForAll,
  changeNodeAtPath,
  insertNode,
  removeNodeAtPath,
  addNodeUnderParent,
  getNodeAtPath,
  find,
  getFlatDataFromTree,
} from "react-sortable-tree";
import TitleComponent from "../TitleComponent";
import SearchSVG from "../../../components/assets/svg/search.svg";
import "react-sortable-tree/style.css";
import "./sortable.scss";
import { dispatch } from "d3";
import {
  GetWorkflowContext,
  SuffelWorkflowContext,
  UpdateWorkflowContext,
} from "../../../actions/WorkflowActions";
import { connect } from "react-redux";
import UpdateModel from "../../Model/UpdateModel";
import Model from "../../Model/WorkFlowModel";
import { FileDownloadGet } from "../../../actions/UtilsActions";
import url from "../../../webconfig.json";

const ExportDataHandler = () => {
  const URL = `${url.proxy}/account/export-all-questions/`;
  const name = `questions_with_id.xlsx`;
  FileDownloadGet(URL, name);
};

class Sortable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: "",
      searchFocusIndex: 0,
      currentNode: {},
      treeData: [],
      model: false,
      preState: [],
      modelData: null,
      addModel: false,
      searchFoundCount: 0,
      allMatchetreeIndex: [],
      search: "",
    };
  }

  expandAndCollapse = (expanded) => {
    this.setState({
      treeData: toggleExpandedForAll({
        treeData: this.state.treeData,
        expanded,
      }),
    });
  };

  updateState = () => {
    this.setState({ treeData: this.props.question });
  };

  componentDidMount() {
    this.updateState();
  }

  updateTreeData(treeData) {
    this.setState({ preState: this.state.treeData });
    this.setState({ treeData });
  }

  removeNode = (path) => {
    this.setState((state) => ({
      treeData: removeNodeAtPath({
        treeData: state.treeData,
        path,
        getNodeKey: ({ treeIndex }) => treeIndex,
      }),
    }));
  };
  selectThis = (node, path) => {
    // console.log(node)
    this.setState({ currentNode: node, path: path });
  };

  //insert new node
  insertNewNode = (newNode, path) => {
    console.log(path);
    const newTree = addNodeUnderParent({
      treeData: this.state.treeData,
      newNode: newNode,
      expandParent: true,
      parentKey: path[path.length - 2],
      getNodeKey: ({ treeIndex }) => treeIndex,
    });
    this.updateTreeData(newTree.treeData);
    // this.props.updateGetWorkflow();
  };

  addNewNode = (newnode, path) => {
    // console.log(path);
    const newTree = addNodeUnderParent({
      treeData: this.state.treeData,
      newNode: newnode,
      expandParent: true,
      parentKey: path ? path[path.length - 1] : undefined,
      getNodeKey: ({ treeIndex }) => treeIndex,
    });
    this.updateTreeData(newTree.treeData);
    // this.props.updateGetWorkflow();
  };

  onMoveHandler = (changeNode) => {
    const currparent = changeNode.path[changeNode.path.length - 2];
    const prevparent = changeNode.prevPath[changeNode.prevPath.length - 2];
    // console.log(currparent, prevparent);
    if (currparent !== prevparent) {
      const modelData = {
        nextParentNode: changeNode.nextParentNode,
        node: changeNode.node,
      };
      this.setState({ model: true, modelData });
    }
    // console.log(changeNode);
  };

  updateNode = (node, path, updateobj, getNodeKey) => {
    // console.log(val)
    // changeNodeAtPath()
    let newTree = changeNodeAtPath({
      treeData: this.state.treeData,
      path,
      getNodeKey,
      newNode: {
        ...node,
        ...updateobj,
      },
    });
    this.setState({ treeData: newTree });
  };

  handleCloseModel = () => {
    this.setState({ model: false, treeData: this.state.preState });
  };
  closeAddModel = () => {
    this.setState({ addModel: false });
  };
  handleUpdateNodeMove = () => {
    const { node, nextParentNode } = this.state.modelData;
    const { question, answer, keywords, url, document, textUrl } = node;
    // console.log(node)
    const updateNode = {
      prev_context: nextParentNode ? nextParentNode.uuid : null,
    };
    this.setState({ model: false });
    this.props.updateNodeParent(node.uuid, updateNode);
  };

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  handleSearchMethod({ node, path, treeIndex, searchQuery }) {
    // console.log("here")
    if (searchQuery.length >= 3) {
      if (
        node.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
        node.answer.toLowerCase().includes(searchQuery.toLowerCase())
      ) {
        // console.log(node);
        // this.setState({treeData: node})
        // this.updateTreeData({node})

        return node;
      }
    }
  }

  selectPrevMatch = () => {
    const { searchFocusIndex, searchFoundCount, allMatchetreeIndex } =
      this.state;
    if (searchFocusIndex > 0) {
      this.setState({
        searchFocusIndex:
          searchFocusIndex !== null
            ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
            : searchFoundCount - 1,
      });
    }
    if (allMatchetreeIndex.length) {
      const el = document.getElementById(
        `${this.state.allMatchetreeIndex[searchFocusIndex - 1]}`
      );
      // console.log(el)
      el.scrollIntoView();
    }
  };

  selectNextMatch = () => {
    const { searchFocusIndex, searchFoundCount, allMatchetreeIndex } =
      this.state;

    if (allMatchetreeIndex.length) {
      let el;
      if (allMatchetreeIndex.length === 1) {
        this.setState({ searchFocusIndex: 1 });
        el = document.getElementById(`${this.state.allMatchetreeIndex[0]}`);
      } else {
        el = document.getElementById(
          `${this.state.allMatchetreeIndex[searchFocusIndex]}`
        );
        // console.log(el)
      }
      if (el) {
        el.scrollIntoView();
      }
    }
    if (searchFoundCount > 0) {
      this.setState({
        searchFocusIndex:
          searchFocusIndex !== null
            ? (searchFocusIndex + 1) % searchFoundCount
            : 0,
      });
    }
  };
  // defaultSearchMethod() {
  //   return (
  //     stringSearch('title', searchQuery, node, path, treeIndex) ||
  //     stringSearch('subtitle', searchQuery, node, path, treeIndex)
  //   );
  // }

  // onSearchHandler = (e)=>{
  //  const val =  find({treeData: this.props.question, getNodeKey: ({ treeIndex }) => treeIndex, searchQuery: e.target.value, searchMethod: (data)=>this.handleSearchMethod(data)})
  //  const allMatched = val.matches.map((e)=>e.node)
  //  this.setState({treeData: allMatched})
  //  console.log(val)
  // }

  handeSearchInput = (e) => {
    this.setState({ search: e.target.value });
    // console.log(this.state.allMatchetreeIndex)
    if (!e.target.value) {
      this.setState({ searchString: "" });
    }
  };

  onSearchHandler = () => {
    this.setState({ searchString: this.state.search });
  };

  handelKeyPress = (event) => {
    if (event.key === "Enter") {
      if (this.state.searchString === this.state.search) {
        if (this.state.allMatchetreeIndex.length) {
          this.selectNextMatch();
        }
      } else {
        // if(this.state.search.length < 1){
        this.onSearchHandler();
        // }
      }
    }
  };

  render() {
    const {
      searchString,
      searchFocusIndex,
      treeData,
      searchFoundCount,
      allMatchetreeIndex,
    } = this.state;
    const getNodeKey = ({ treeIndex }) => treeIndex;
    // console.log(this.state.treeData)
    return (
      <>
        <div className="top-header">
          <span
            className="addNewbtn"
            onClick={() => this.setState({ addModel: true })}
          >
            Add New Question
          </span>
          <span className="addNewbtn" onClick={ExportDataHandler}>
            Export Data
          </span>
          <div className="search-area">
            {/* <button  onClick={() => { this.expandAndCollapse(true); }}>Expand all</button>
          <button  onClick={() => { this.expandAndCollapse(false); }}>Collapse all</button> */}
            &nbsp;&nbsp;&nbsp;
            <div className="search-area-input-container">
              <img src={SearchSVG} alt="search icon" />
              <input
                placeholder="Search"
                onChange={this.handeSearchInput}
                onKeyDown={this.handelKeyPress}
              />
              <button
                onClick={this.onSearchHandler}
                title="Search"
                disabled={this.state.search.length < 1}
              >
                Search
              </button>
            </div>
            <label>
              {searchFocusIndex} / {searchFoundCount}
            </label>
            <span className="previous" onClick={this.selectPrevMatch}>
              Previous
            </span>
            <span className="next" onClick={this.selectNextMatch}>
              Next
            </span>
          </div>
        </div>
        <div className="container">
          <SortableTree
            searchQuery={searchString}
            searchMethod={(data) => this.handleSearchMethod(data)}
            rowHeight={({ node }) => {
              return node.expanded ? 100 : 50; // Custom logic for height
            }}
            searchFocusOffset={searchFocusIndex}
            treeData={treeData}
            onChange={(treedata) => this.updateTreeData(treedata)}
            onMoveNode={(data) => this.onMoveHandler(data)}
            searchFinishCallback={(matches) => {
              if (!this.state.searchString) {
                this.setState({
                  allMatchetreeIndex: [],
                  searchFoundCount: 0,
                  searchFocusIndex: 0,
                });
              }
              if (this.state.searchString.length) {
                this.setState({
                  searchFoundCount: matches.length,
                  allMatchetreeIndex: matches.map((e) => e.treeIndex),
                  searchFocusIndex:
                    matches.length > 0 ? searchFocusIndex % matches.length : 0,
                });
              }
            }}
            generateNodeProps={({
              node,
              path,
              isSearchMatch,
              isSearchFocus,
              treeIndex,
            }) => ({
              title: (
                <TitleComponent
                  isSearchFocus={isSearchFocus}
                  isSearchMatch={isSearchMatch}
                  node={node}
                  path={path}
                  treeIndex={treeIndex}
                  allMatchetreeIndex={allMatchetreeIndex}
                  setState={this.setState}
                  searchFocusIndex={searchFocusIndex}
                  getNodeKey={getNodeKey}
                  insertNewNode={this.insertNewNode}
                  addNodeUnderParent={this.addNewNode}
                  removeNode={this.removeNode}
                  selectThis={this.selectThis}
                  updateNode={this.updateNode}
                />
              ),
            })}
            style={{ height: "auto" }}
          />
        </div>

        {this.state.addModel && (
          <Model
            closeModel={this.closeAddModel}
            uuid={null}
            insertNewNode={this.addNewNode}
          />
        )}
        {this.state.model && (
          <UpdateModel
            data={this.state.modelData}
            handleUpdate={this.handleUpdateNodeMove}
            closeModel={this.handleCloseModel}
          />
        )}
      </>
    );
  }
}

const DispatchStateToProps = (dispatch) => ({
  updateNodeParent: (uuid, updateNode) =>
    dispatch(SuffelWorkflowContext(uuid, updateNode)),
  updateGetWorkflow: () => dispatch(GetWorkflowContext()),
});

export default connect(null, DispatchStateToProps)(Sortable);
