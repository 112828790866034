export const UnttagedReducers = (
  state = {
    untaggedQuestion: null,
  },
  action
) => {
  switch (action.type) {
    case "GET_DASHBOARD_REQUEST":
      return { loading: true };

    case "GET_DASHBOARD_SUCCESS":
      return { loading: false, untaggedQuestion: action.payload };

    case "GET_DASHBOARD_FAIL":
      return { loading: false, error: action.payload };

    case "GET_DASHBOARD_RESET":
      return {};

    default:
      return state;
  }
};
export const userFeedback = (
  state = {
    untaggedQuestion: null,
  },
  action
) => {
  switch (action.type) {
    case "GET_USERFEEDBACK_REQUEST":
      return { loading: true };

    case "GET_USERFEEDBACK_SUCCESS":
      return { loading: false, userFeedback: action.payload };

    case "GET_USERFEEDBACK_FAIL":
      return { loading: false, error: action.payload };

    case "GET_USERFEEDBACK_RESET":
      return {};

    default:
      return state;
  }
};


export const FrequentlyReducers = (
    state = {
      FrequentlyQuestion: null,
    },
    action
  ) => {
    switch (action.type) {
      case "GET_FREQUENTLY_REQUEST":
        return { loading: true };
  
      case "GET_FREQUENTLY_SUCCESS":
        return { loading: false, FrequentlyQuestion: action.payload };
  
      case "GET_FREQUENTLY_FAIL":
        return { loading: false, error: action.payload };
  
      case "GET_FREQUENTLY_RESET":
        return {};
  
      default:
        return state;
    }
  };

  export const AllUserReducers = (
    state = {
      allUser: null,
    },
    action
  ) => {
    switch (action.type) {
      case "GET_ALLUSER_REQUEST":
        return { loading: true };
  
      case "GET_ALLUSER_SUCCESS":
        return { loading: false, allUser: action.payload };
  
      case "GET_ALLUSER_FAIL":
        return { loading: false, error: action.payload };
  
      case "GET_ALLUSER_RESET":
        return {};
  
      default:
        return state;
    }
  };

  export const RatingsContext = (
    state = {
      allRatings: null,
    },
    action
  ) => {
    switch (action.type) {
      case "GET_RATINGS_REQUEST":
        return { loading: true };
  
      case "GET_RATINGS_SUCCESS":
        return { loading: false, allRatings: action.payload };
  
      case "GET_RATINGS_FAIL":
        return { loading: false, error: action.payload };
  
      case "GET_RATINGS_RESET":
        return {};
  
      default:
        return state;
    }
  };

  export const SatisfactionReducer = (
    state = {
      persentageData: null,
    },
    action
  ) => {
    switch (action.type) {
      case "GET_SATISFACTION_REQUEST":
        return { loading: true };
  
      case "GET_SATISFACTION_SUCCESS":
        return { loading: false, persentageData: action.payload };
  
      case "GET_SATISFACTION_FAIL":
        return { loading: false, error: action.payload };
  
      case "GET_SATISFACTION_RESET":
        return {};
  
      default:
        return state;
    }
  };

  export const ConversationsReducer = (
    state = {
      conversations: null,
    },
    action
  ) => {
    switch (action.type) {
      case "GET_CONVERSATIONS_REQUEST":
        return { loading: true };
  
      case "GET_CONVERSATIONS_SUCCESS":
        return { loading: false, conversations: action.payload };
  
      case "GET_CONVERSATIONS_FAIL":
        return { loading: false, error: action.payload };
  
      case "GET_CONVERSATIONS_RESET":
        return {};
  
      default:
        return state;
    }
  };