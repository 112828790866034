import { errorHandler } from "./UtilsActions";
import axios from "axios";
import url  from '../webconfig.json'
export const GetUserLogsContext = (URLaddres,requestData) => async (dispatch, getState) => {
    try{

        dispatch({ 
            type: "GET_USERLOGS_REQUEST"
        })

        const {
            userLogin: { userInfo },
        } = getState()
        // console.log(userInfo.access)
        const config = {
            headers:{
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.access}`
            }
        };
        if(URLaddres){
            const {data} = await axios.get(
                URLaddres.includes("idia-backend.linkintime") ? URLaddres.replace("http:", "https:") :URLaddres,config
                )
                dispatch({
                    type: "GET_USERLOGS_SUCCESS",
                    payload: data
                })
        }
        else if(requestData){
            // console.log("here")
            const {data} = await axios.post(
                ` ${url.proxy}/account/search-userlogs/`,requestData,config,
                )
                dispatch({
                    type: "GET_USERLOGS_SUCCESS",
                    payload: data
                })
        }
        else{
            const {data} = await axios.get(
                ` ${url.proxy}/account/userlogs/`,config
                )
                dispatch({
                    type: "GET_USERLOGS_SUCCESS",
                    payload: data
                })
        }
       
            // console.log(data)
       

        // dispatch(users())

    }catch(error){
        errorHandler(dispatch, error, "GET_USERLOGS_FAIL")

        // dispatch({
        //     type: "GET_USERLOGS_FAIL",
        //     payload: (error.response && error.response.data.detail
        //         ? error.response.data.detail 
        //         : error.message)
        // })
    }
};