import React from "react";
import * as d3 from 'd3'

class Chart extends React.Component {
  constructor(props) {
    super(props);
  }

  /**
   * Need to be called because when it's instanciated id="chart"
   * doesn't exist yet when first render is called.
   * So we call update() a second time.
   */
  componentDidMount() {
    this.update();
  }

  /**
   * D3 function.
   * It displays svg bar chart.
   * It doesn't manager D3 exit() function: updates come
   * from react parent component Graph.
   */
  update = () => {
    // const { dataset } = this.props;
    const dataset = [...this.props.dataset]
    dataset.pop()
    // console.log(dataset);

    // Disposal
    let w = 400;
    let h = 180;
    let margin = 30;
    let barPadding = 30;

    /*
    * Each time it removes old chart and displays new chart.
    * It's requisite because we don't use D3 exit().remove function to update.
    * If it is not done, charts ("svg" elements) are added one after other.
    */
    d3.select("svg").remove();

    /*
    * Calculates the good scale for y axis
    * It must be applied to data too.
    */
    let yScale = d3
      .scaleLinear()
      // Entries data range: from 0 to data max
      .domain([
        0,
        d3.max(dataset, function(d) {
          // console.log(d)
          return d.val;
        })
      ])
      // Area available for the y axis : the height in pixels
      .range([h, 0]);
    
      
    // Creates SVG bars
    let svg = d3
      .select(".chart")
      .append("svg")
      .attr("width", w + 2 * margin)
      .attr("height", h + 2 * margin)
      .append("g")
      
      .attr("transform", "translate(" + margin + "," + margin + ")");

       //test==============
       var defs = svg.append('defs');
       var lg = defs.append('linearGradient')
  .attr('id', 'Gradient2')
  .attr('x1', 0)
  .attr('x2', 0)
  .attr('y1', 0)
  .attr('y2', 1);
 
 lg.append('stop')
  .attr('offset', '0%')
  .attr('stop-color', '#389CE6');
 
 lg.append('stop')
  .attr('offset', '100%')
  .attr('stop-color', "#252D59");
  //=====================

    svg
      .selectAll("rect")
      .data(dataset)
      .enter()
      .append("rect")
      .attr("x", function(d, i) {
        // console.log(d)
        return i * (w / dataset.length);
      })
      .attr('rx', 7)
      
      .attr("y", function(d) {
        return yScale(d.val);
      })
      .attr("cursor", "pointer")
      
      .attr("width", w / dataset.length - barPadding)
      .attr("height", function(d) {
        return h - yScale(d.val);
      })
      
      .attr("cursur", "pointer")
      .attr("fill", "url(#Gradient2)");

     
   
    
     
    // Writes labels on bars
    svg
      .selectAll("text")
      .data(dataset)
      .enter()
      .append("text")
      .text(function(d) {
        return d.val;
      })
      .attr("x", function(d, i) {
        return i * (w / dataset.length) + (w / dataset.length - barPadding) / 2;
      })
      .attr("y", function(d) {
        return yScale(d.val) + 10;
      })
      .attr("font-family", "sans-serif")
      .attr("font-size", "16px")
      .attr("fill", "white")
      .attr("text-anchor", "middle")
      .attr("dy", "10");

      const x = d3.scaleBand()
      .rangeRound([0, w])
      .domain(dataset.map(d => d.rating));
    // Finally draws y axis
    // let yAxis = d3.axisLeft(yScale);
    const xAxis = d3.axisBottom(x);
  svg.append('g')
  .attr('class', 'axis')
  .attr("border", "1")
  .attr('transform', `translate(0, ${h})`)
  
  .call(xAxis).attr('stroke-width', 0)
  .selectAll(".tick text").attr("x","-15")
  .attr("font-family", "Poppins")
      .attr("font-size", "16px")
      .attr("fill", "#252D59")
      .attr("font-weight", "500")
      .attr("text-anchor", "middle");
    // let xAxis = d3.axisBottom(xScale)
    // svg.append("g").call(yAxis);
  };

  render() {
    this.update();
    return <div className="chart" />;
  }
}
export default Chart