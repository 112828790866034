import React, { useEffect, useState} from "react";
import ReactDOM from "react-dom";
import ReactQuill from "react-quill";

import { useDispatch } from "react-redux";
import { getContextDetails } from "../../actions/ApiCall";
import { DeleteWorkflowContext, UpdateWorkflowContext } from "../../actions/WorkflowActions";
import "../../styles/updateModel.scss";
import "react-quill/dist/quill.snow.css";
import DeleteSVG from "../assets/svg/DeleteSVG.svg";

const DeleteModel = (props) => {
  const dispatch = useDispatch()
  useEffect(() => {
    // console.log(props.node)


   
  }, []);


const handleDelete= ()=>{
  dispatch(DeleteWorkflowContext(props.node.uuid, props.node.question))
  props.removeNode(props.path)
  props.closeModel()
}



  return ReactDOM.createPortal(
    (
      <div className="delete">
        <span>Do you want to delete the question ?</span>
        <div className="delete-btn">
        <span  className="delete-btn-yes" onClick={handleDelete}>YES</span>
        <span  className="delete-btn-no" onClick={props.closeModel}>CANCEL</span>
        </div>
        
      </div>
    ),
    document.getElementById("model")
  );
};
{
}

export default DeleteModel;
