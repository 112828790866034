import React from "react";
import { Quill } from "react-quill";
import "../../styles/editorToolbar.scss";
// import { v4 as uuidv4 } from 'uuid';

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomeReadMore = () => (
  <span className="Read-more">Read More</span>
);
const CustomeFocusAddress = () => (
  <span className="focus-Address">Address</span>
);
const CustomePopup = () => (
  <span className="focus-Address">PopUp</span>
);

const CustomeTypeDropdown = () =>(
  <>
  <select className="ql-types" title="Select Email Topic">
        <option value="R-T">Email-R&T</option>
        <option value="Public-Issues">Email Public Issues</option>
        <option value="Bonds">Email Bonds</option>
        {/* <option value="medium">Size 3</option>
        <option value="large">Size 4</option> */}
      </select>
      </>
)

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

// Undo and redo functions for Custom Toolbar
function readChange() {
    
        
        this.quill.insertText(this.quill.getSelection().index, '__Read__more__', 'link', '/');
        
    
}
function FocusAddressChange() {
    
        
        this.quill.insertText(this.quill.getSelection().index, 'Focus Address', 'link', '/');
        console.log(this.quill.getText())
    
}

// var Block = Quill.import('blots/block');

let Block = Quill.import('blots/block');
// let id = uuidv4()
  class Warning extends Block {
   
    static create(value) {
      const node = super.create()
        // let node = super.create(value);
        console.log()
        node.setAttribute('class', `popup`);
        // node.setAttribute('id', id);
        return node;
    }  

    // static formats(node) {
    //   return { email: id };
    // }
  }
  Warning.blotName = 'popup';
  Warning.tagName = 'div';
  Quill.register(Warning,true);
  
// function popup() {
//   id = uuidv4()
//   // var selection = this.quill.getSelection();
//   // var selectedContent = this.quill.getContents(selection.index, selection.length);
//   // var tempContainer = document.createElement('div')
//   // var tempQuill = new Quill(tempContainer);
//   // tempQuill.setContents(selectedContent);

//   // const Container = document.createElement("p")
//   // Container.innerHTML = tempContainer.querySelector('.ql-editor').innerHTML
//   // Container.id = uuidv4()
  

//   // // this.quill.deleteText(selection.index, selection.length)
//   // console.log(selection.index)
//   // const stringHtml = Container.outerHTML
//   // this.quill.clipboard.dangerouslyPasteHTML(selection.index, '<p id="test">Hello</p>');
//   // console.log(stringHtml);
//   //     // id = uuidv4()
   
    
// }



function EmailTypeChange(e){
  // console.log(this,e)
  this.quill.insertText(this.quill.getSelection().index, `Email-${e}`, 'link', '/');
  console.log(this.quill.getText())
}
function redoChange() {
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida"
];
Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
    
    handlers: {
      read: readChange,
      address: FocusAddressChange,
      redo: redoChange,
      types:EmailTypeChange,
    }
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "types",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
  "popup"
];

// Quill Toolbar component
export const QuillToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
      {/* <select className="ql-font" defaultValue="arial">
        <option value="arial">Arial</option>
        <option value="comic-sans">Comic Sans</option>
        <option value="courier-new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="helvetica">Helvetica</option>
        <option value="lucida">Lucida</option>
      </select> */}
      <select className="ql-size" defaultValue="medium" title="Text Size">
        <option value="extra-small">Size 1</option>
        <option value="small">Size 2</option>
        <option value="medium">Size 3</option>
        <option value="large">Size 4</option>
      </select>
      <select className="ql-header" defaultValue="3" title="Heading">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" title="Bold" />
      <button className="ql-italic" title="Italic"/>
      <button className="ql-underline" title="underline" />
      <button className="ql-strike" title="Strike"/>
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered"  title="Ordered List"/>
      <button className="ql-list" value="bullet" title="Bullet Points" />
      {/* <button className="ql-indent" value="-1" /> */}
      {/* <button className="ql-indent" value="+1" /> */}
    </span>
    <span className="ql-formats">
      {/* <button className="ql-script" value="super" /> */}
      {/* <button className="ql-script" value="sub" /> */}
      <button className="ql-blockquote"  title="Blockquote"/>
      <button className="ql-direction" title="Direction" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" title="Align Text" />
      <select className="ql-color" title="Text Color" />
      <select className="ql-background" title="Background Color" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" title="Add URL" />
      {/* <button className="ql-image" /> */}
      {/* <button className="ql-video" /> */}
    </span>

    {/* <span className="ql-formats">
      <button className="ql-formula" />
      <button className="ql-code-block" />
      <button className="ql-clean" />
    </span> */}
    <span className="ql-formats btn-format">
      <button className="ql-read" title="Add Read More">
        <CustomeReadMore />
      </button>
      <button className="ql-address" title="Add Focus Address">
        <CustomeFocusAddress />
      </button>
      <button className="ql-popup" title="Add popup">
        <CustomePopup />
      </button>

    
        <CustomeTypeDropdown/>
     
      
      
    
      {/* <button className="ql-redo">
        <CustomRedo />
      </button> */}
    </span>
    {/* <span className="ql-formats">
    
    </span> */}
  </div>
);

export default QuillToolbar;
