import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

import HomeSVG from "../assets/svg/homeSVG.svg";
import WorkflowSvg from "../assets/svg/workflowSVG.svg";
import DashboardSvg from "../assets/svg/DashboardSVG.svg";
import userSvg from "../assets/svg/userSVG.svg";
import DeleteLogsSvg from "../assets/svg/DeleteDarkSvg.svg"
import Logo from "../Logo/Logo";
import "../../styles/SideNav.scss";

const SideNav = () => {
  const { userInfo } = useSelector((state) => state.userLogin);
  const location = useLocation();
  const currentLocation = location.pathname;
  return (
    <div className="sidenav">
      <div className="sidenav-container">
        <div  className="sidenav-container-logo">
        <Logo />
        </div>
        
        <div className="sidenav-container-username">
          <span className="sidenav-container-username-img">
            {userInfo.first_name.charAt(0) + userInfo.last_name.charAt(0)}
          </span>
          <span className="sidenav-container-username-text">
            {userInfo.first_name} {userInfo.last_name}
          </span>
        </div>
        <ul className="sidenav-container-list">
          <NavLink to="/home">
            <li
              className={`sidenav-container-list-item ${
                currentLocation === "/home" &&
                "sidenav-container-list-item-active"
              }`}
            >
              <img src={HomeSVG} alt="home" /> Home
            </li>
          </NavLink>
          <NavLink to="/workflow">
            <li
              className={`sidenav-container-list-item ${
                currentLocation === "/workflow" &&
                "sidenav-container-list-item-active"
              }`}
            >
              <img src={WorkflowSvg} alt="workflow" /> Workflow
            </li>
          </NavLink>
          <NavLink to="/dashboard">
            <li
              className={`sidenav-container-list-item ${
                currentLocation === "/dashboard" &&
                "sidenav-container-list-item-active"
              }`}
            >
              <img src={DashboardSvg} alt="dashboard" /> Dashboard
            </li>
          </NavLink>
          <NavLink to="/newuser">
            <li
              className={`sidenav-container-list-item ${
                currentLocation === "/newuser" &&
                "sidenav-container-list-item-active"
              }`}
            >
              <img src={userSvg} alt="new user" /> Add new user
            </li>
          </NavLink>
          <NavLink to="/deletelogs">
            <li
              className={`sidenav-container-list-item ${
                currentLocation === "/deletelogs" &&
                "sidenav-container-list-item-active"
              }`}
            >
              <img src={DeleteLogsSvg} alt="new user" /> Deleted logs
            </li>
          </NavLink>
        </ul>
      </div>
    </div>
  );
};

export default SideNav;
