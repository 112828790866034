import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetDeletedLogsContext, RestoreDeletedLogsContext, SearchDeltedLogs } from '../actions/DeletedLogsAction'
import "../styles/deletedlogs.scss"
// import SearchSVG from "../components/assets/svg/search.svg";
import Table from '../components/Tables/Table';

const DeletedLogsPage = () => {
    // const [search,setSearch] = useState("");
    const {logs} = useSelector(state=> state.deleteLogs)
    
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(GetDeletedLogsContext())
    },[])


    const handleRestore = (id,type)=>{
        console.log(id)
        // const istypethere = type ? type : null
        dispatch(RestoreDeletedLogsContext(id,null))
       
    }


  return (
    <div>

{ logs && logs.results && <Table 
      title=""
      HeadingOption={
        [
        {name: "Context Name"},
        {name: "keywords"},
        {name: "Date"},
        {name: "Username"},
      ]}
      current={logs.links ?  logs.links.current : logs.current}
      previous={logs.links ? logs.links.previous : logs.previous }
      next={logs.links ?  logs.links.next : logs.next}
      // EnableDatePicker={true}
      // EnableCSVExport={true}

      handlePaginateAction={GetDeletedLogsContext}
      handleSearchAction={SearchDeltedLogs}
      handleRestore={handleRestore}
      // handleStatusAction={updateFeedbackStatus}
      // hanldeCSVFileAction={getCSVFile}
      EnableSearch={true}
      DataOption={logs.results.map(((e,el)=>{
        return [
          {value: e.question.context_name, class: "deletedlogs-align"},
          {value: `${e.keywords.tags.map((e)=>e)}`,class: "deletedlogs-align"},
          {value: e.created_at.split("T")[0]},
          {value: e.user_name, type: "restore", id: e.id},
        ]
      }))}
      />}
    </div>
  )
}

export default DeletedLogsPage