import { errorHandler } from "./UtilsActions";
import axios from "axios";
import url  from '../webconfig.json'
import { logout } from "./UserLoginActions";
export const GetWorkflowContext = () => async (dispatch, getState) => {
    try{

        dispatch({ 
            type: "GET_WORKFLOW_REQUEST"
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers:{
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.access}`
            }
        };
        

        const {data} = await axios(
            `${url.proxy}/account/admin-context/`,config
            )
            console.log(data)
        dispatch({
            type: "GET_WORKFLOW_SUCCESS",
            payload: data
        })

        // dispatch(users())

    }catch(error){
        errorHandler(dispatch, error, "GET_WORKFLOW_FAIL")
        // dispatch({
        //     type: "GET_WORKFLOW_FAIL",
        //     payload: (error.response && error.response.data.detail
        //         ? error.response.data.detail 
        //         : error.message)
        // })
    }
};

export const SuffelWorkflowContext = (uuid,updateData) => async (dispatch, getState) => {
    try{

        dispatch({ 
            type: "UPDATE_WORKFLOW_REQUEST"
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers:{
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.access}`
            }
        };
        

        const {data} = await axios.patch(
            `${url.proxy}/account/admin-context-detail/${uuid}/`,updateData,config
            )
            console.log(typeof(data))
            if(typeof(data) === "string"){
                dispatch({type: "CREATE_NOTIFICATION", payload: {message: data, type: "success"}})
            }
        dispatch({
            type: "UPDATE_WORKFLOW_SUCCESS",
            payload: data
        })

        // dispatch(users())

    }catch(error){
        if(error.response){
            console.log(error.response)
        dispatch({type: "CREATE_NOTIFICATION", payload: {message:error.response.data[0], type: "error" }})
        }
        if(error.response.status === 401){
            dispatch({
                type: "UPDATE_WORKFLOW_FAIL",
                payload: (error.response && error.response.data.detail
                    ? error.response.data.detail 
                    : error.message)
            })
            dispatch({type: "CREATE_NOTIFICATION", payload: {message: "User authentication failed please log in Again.", type: "error"}})
            
            setTimeout(() => {
                dispatch(logout())
              }, 5000);
        }
    }
};
export const UpdateWorkflowContext = (uuid,updateData) => async (dispatch, getState) => {
    try{

        dispatch({ 
            type: "UPDATE_WORKFLOW_REQUEST"
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers:{
                'Content-type': 'multipart/form-data',
                'Authorization': `Bearer ${userInfo.access}`
            }
        };
        

        const {data} = await axios.put(
            `${url.proxy}/account/admin-context-detail/${uuid}/`,updateData,config
            )
            console.log(typeof(data))
            if(typeof(data) === "string"){
                dispatch({type: "CREATE_NOTIFICATION", payload: {message: data, type: "success"}})
            }
        dispatch({
            type: "UPDATE_WORKFLOW_SUCCESS",
            payload: data
        })

        // dispatch(users())

    }catch(error){
        if(error.response){
            console.log(error.response)
        dispatch({type: "CREATE_NOTIFICATION", payload: {message:error.response.data[0], type: "error" }})
        }
        if(error.response.status === 401){
            dispatch({
                type: "UPDATE_WORKFLOW_FAIL",
                payload: (error.response && error.response.data.detail
                    ? error.response.data.detail 
                    : error.message)
            })
            dispatch({type: "CREATE_NOTIFICATION", payload: {message: "User authentication failed please log in Again.", type: "error"}})
            
            setTimeout(() => {
                dispatch(logout())
              }, 5000);
        }
    }
};

export const CreateWorkflowContext = (newRequestData,insertNewAddedNode) => async (dispatch, getState) => {
    try{

        dispatch({ 
            type: "CREATE_WORKFLOW_REQUEST"
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers:{
                'Content-type': 'multipart/form-data',
                'Authorization': `Bearer ${userInfo.access}`
            }
        };
        

        const {data} = await axios.post(
            `${url.proxy}/account/admin-context-creation/`,newRequestData,config
            )
            // if(typeof(data) === "string"){
            //     dispatch({type: "CREATE_NOTIFICATION", payload: {message: data, type: "success"}})
            // }
            console.log(data)
        dispatch({
            type: "CREATE_WORKFLOW_SUCCESS",
            payload: data
        })

        if(data){
            insertNewAddedNode(data)
            dispatch({type: "CREATE_NOTIFICATION", payload: {message: data.status, type: "success"}})
        }
        // dispatch(GetWorkflowContext())

    }catch(error){
        if(error.response){
            console.log(error.response)
        dispatch({type: "CREATE_NOTIFICATION", payload: {message:error.response.data[0], type: "error" }})
        }
        console.log(error)
        if(error.response.status === 401){
            dispatch({
                type: "CREATE_WORKFLOW_FAIL",
                payload: (error.response && error.response.data.detail
                    ? error.response.data.detail 
                    : error.message)
            })
            dispatch({type: "CREATE_NOTIFICATION", payload: {message: "User authentication failed please log in Again.", type: "error"}})
            
            setTimeout(() => {
                dispatch(logout())
              }, 5000);
        }
    }
};


export const CopyContextWorkflow = (copuRequestData, closeModel, insetcopyNode) => async (dispatch, getState) => {
    try{

        dispatch({ 
            type: "CREATE_WORKFLOW_REQUEST"
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers:{
                'Content-type': 'multipart/form-data',
                'Authorization': `Bearer ${userInfo.access}`
            }
        };
        

        const {data} = await axios.post(
            `${url.proxy}/account/context-duplication/`,copuRequestData,config
            )
            // if(typeof(data) === "string"){
            //     dispatch({type: "CREATE_NOTIFICATION", payload: {message: data, type: "success"}})
            // }
            console.log(data)
        dispatch({
            type: "CREATE_WORKFLOW_SUCCESS",
            payload: data
        })

        // dispatch(GetWorkflowContext())
        if(data){
        closeModel()
        dispatch({type: "CREATE_NOTIFICATION", payload: {message: data.status, type: "success"}})
        insetcopyNode(data)
        }
        

    }catch(error){
        if(error.response){
            console.log(error.response)
        dispatch({type: "CREATE_NOTIFICATION", payload: {message:error.response.data[0], type: "error" }})
        }
        if(error.response.status === 401){
            dispatch({
                type: "CREATE_WORKFLOW_FAIL",
                payload: (error.response && error.response.data.detail
                    ? error.response.data.detail 
                    : error.message)
            })
            dispatch({type: "CREATE_NOTIFICATION", payload: {message: "User authentication failed please log in Again.", type: "error"}})
            
            setTimeout(() => {
                dispatch(logout())
              }, 5000);
        }
       
    }
};
export const DeleteWorkflowContext = (uuid,questioName) => async (dispatch, getState) => {
    try{

        // dispatch({ 
        //     type: "CREATE_WORKFLOW_REQUEST"
        // })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers:{
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.access}`
            }
        };
        

        const {data} = await axios.delete(
            `${url.proxy}/account/admin-context-delete/${uuid}/`,config
            )
            console.log(data)
            dispatch({type: "CREATE_NOTIFICATION", payload: {message:`Deleted ${questioName} Successfully `, type: "success" }})
        // dispatch({
        //     type: "CREATE_WORKFLOW_SUCCESS",
        //     payload: data
        // })

        // dispatch(users())

    }catch(error){
        errorHandler(dispatch, error, "CREATE_WORKFLOW_FAIL")
        // dispatch({
        //     type: "DELETE_WORKFLOW_FAIL",
        //     payload: (error.response && error.response.data.detail
        //         ? error.response.data.detail 
        //         : error.message)
        // })
    }
};


export const GetWorkflowEditContext = (uuid) => async (dispatch, getState) => {
    try{

        dispatch({ 
            type: "GET_WORKFLOWEDIT_REQUEST"
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers:{
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.access}`
            }
        };
        

        const {data} = await axios(
            `${url.proxy}/account/admin-context-detail/${uuid}`,config
            )
            console.log(data)
        dispatch({
            type: "GET_WORKFLOWEDIT_SUCCESS",
            payload: data
        })

        // dispatch(users())

    }catch(error){
        errorHandler(dispatch, error, "GET_WORKFLOWEDIT_FAIL")

        // dispatch({
        //     type: "GET_WORKFLOWEDIT_FAIL",
        //     payload: (error.response && error.response.data.detail
        //         ? error.response.data.detail 
        //         : error.message)
        // })
    }
};

