import React, { useEffect, useRef, useState } from "react";
import "../styles/login.scss";

import VisibleSvg from "../components/assets/svg/visible.svg";
import LoginImg from "../components/assets/svg/logoImg.svg";
import Logo from "../components/Logo/Logo";
import { useDispatch, useSelector } from "react-redux";
import { ForgetPassword, login, logout } from "../actions/UserLoginActions";
import Notification from "../components/Notification";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Login = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector(state=> state.userLogin)
  const passwordRef = useRef();
  const [passwrod, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [forget, setForget] = useState(false);
  const [ForgetEmail, setForgetEmail] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false)
 const history = useHistory();


  useEffect(()=>{

    if(userLogin.error){
      setEmail("")
      setPassword("")
    }

  }, [userLogin.error])

  const changeVisibleHandler = () => {
    const type = passwordRef.current.type;
    if (type === "password") {
      passwordRef.current.type = "text";
    } else {
      passwordRef.current.type = "password";
    }
  };

  const handleSubmit = (e) =>{
      e.preventDefault()
      if(email.trim() && passwrod.trim()){
        dispatch(login(email, passwrod))
      }
     
  }

  const userRedirect = (redirect) =>{
    setBtnDisabled(false)
    setForgetEmail("")
    if(redirect){
       setForget(false)
    }

  }

  const handleResetPasswrd = (e) =>{
    e.preventDefault()
    if(ForgetEmail.trim()){
      setBtnDisabled(true)
      dispatch(ForgetPassword(ForgetEmail,userRedirect))
    }
  }

  const closeError = ()=>{
      dispatch(logout())
  }
  return (
    <div className="login">
       <Notification/>
      <div className="login-logo">
      <Logo />
      </div>
      <div className="login-page">
        <div className="login-img">
          <img src={LoginImg} alt="login Vector" />
        </div>
        
        <div className="login-page-container">
        <div className="login-page-container-error">
            {userLogin.error && <span className="login-page-container-error-container">{userLogin.error} <span className="login-page-container-error-container-close" onClick={closeError}>+</span> </span>}
        </div>
        { forget ?  <form className="login-page-container-form" onSubmit={handleResetPasswrd}>
            <div className="login-page-container-form-text">
              <h3 className="login-page-container-form-text-head">
                Reset Your Password
              </h3>
              <span className="login-page-container-form-text-text">
              Reset your I-Dia chatbot Password
              </span>
            </div>
            <div className="login-page-container-form-input">
              <div className="login-page-container-form-input-email">
                <input
                  type="email"
                  placeholder="Email"
                  value={ForgetEmail}
                  onChange={(e) => setForgetEmail(e.target.value)}
                  required
                />
              </div>
              {/* <div className="login-page-container-form-input-password">
                <input
                  type="password"
                  ref={passwordRef}
                  value={passwrod}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                />
                <img
                  src={VisibleSvg}
                  onClick={changeVisibleHandler}
                  alt="show passowrd"
                />
              </div> */}
            </div>
            <div className="login-page-container-form-bottom">
              <button className="login-page-container-form-bottom-submit" disabled={btnDisabled}>
              RESET PASSWORD
              </button>
              <span onClick={()=>setForget(false)} className="login-page-container-form-bottom-forget">
                Go back to login?
              </span>
            </div>
          </form>  :<form className="login-page-container-form" onSubmit={handleSubmit}>
            <div className="login-page-container-form-text">
              <h3 className="login-page-container-form-text-head">
                Welcome Back!
              </h3>
              <span className="login-page-container-form-text-text">
                Login to your I-Dia chatbot
              </span>
            </div>
            <div className="login-page-container-form-input">
              <div className="login-page-container-form-input-email">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="login-page-container-form-input-password">
                <input
                  type="password"
                  ref={passwordRef}
                  value={passwrod}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                />
                <img
                  src={VisibleSvg}
                  onClick={changeVisibleHandler}
                  alt="show passowrd"
                />
              </div>
            </div>
            <div className="login-page-container-form-bottom">
              <button className="login-page-container-form-bottom-submit" >
                SUBMIT
              </button>
              <span onClick={()=>setForget(true)} className="login-page-container-form-bottom-forget">
                Forget password?
              </span>
            </div>
          </form>}
          {/* <div className="login-page-container-terms">
            <span>Terms and condition</span> & <span>Privacy policy</span> apply
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
