import React, { useEffect, useState} from "react";
import ReactDOM from "react-dom";
import ReactQuill from "react-quill";

import { useDispatch } from "react-redux";
import { getContextDetails } from "../../actions/ApiCall";
import { DeleteWorkflowContext, UpdateWorkflowContext } from "../../actions/WorkflowActions";
import "../../styles/deleteModel.scss";
import "react-quill/dist/quill.snow.css";
import DeleteSVG from "../assets/svg/DeleteSVG.svg";

const UpdateModel = (props) => {

    const {nextParentNode,node} = props.data






  return ReactDOM.createPortal(
    (
      <div className="update">
        <span>You Are Moving  <span className="update-heiglight">{node.question}</span> inside <span className="update-heiglight">{nextParentNode ? nextParentNode.question : "root"}</span> <br/> Are you sure about this change.? </span>
        <div className="update-btn">
        <span  className="update-btn-yes" onClick={props.handleUpdate}>YES</span>
        <span  className="update-btn-no" onClick={props.closeModel}>CANCEL</span>
        </div>
        
      </div>
    ),
    document.getElementById("model")
  );
};
{
}

export default UpdateModel;
