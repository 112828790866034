const initailState = {
    logs:[]
}

export const DeletedLogsReducer = (state=initailState, action) => {
    

    switch (action.type) {
        case "GET_DELETEDLOGS_REQUEST": 
            return { loading: true ,...state}

        case "GET_DELETEDLOGS_SUCCESS": 
            return {loading: false, logs: action.payload}

        case "GET_DELETEDLOGS_FAIL":
            return {loading: false, error: action.payload}

        case "GET_DELETEDLOGS_RESET":
            return {}

        default:
            return state
    }
}