import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNewUser, ResetUser } from "../actions/UserListActions";
import "../styles/addnewuser.scss";
const AddNewUser = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [suggestedPassword, setSuggestedPassword] = useState(false);
  const [genPassword, setGenPassword] = useState(null);
const suggestedRef = useRef()
  const newUserInfo = useSelector((state) => state.newUserInfo);
  
  useEffect(() => {
    if (newUserInfo.error || newUserInfo.success) {
      setTimeout(() => {
        dispatch(ResetUser());
      }, 6000);
    }
    if (newUserInfo.success) {
      formRef.current.reset();
    }
    window.addEventListener("click", displaySuggestedPassword)
  }, [newUserInfo.error, newUserInfo.success]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(ResetUser());
    const newuser = {};
    newuser.first_name = formRef.current.first_name.value;
    newuser.last_name = formRef.current.last_name.value;
    newuser.password = formRef.current.password.value;
    newuser.email = formRef.current.email.value;
    newuser.ConfirmPassword = formRef.current.cpassword.value;
    newuser.group_name = formRef.current.usertype.value;

    const { password, ConfirmPassword } = newuser;
    if (password === ConfirmPassword) {
      dispatch(createNewUser(newuser));

      // formRef.current.cpassword.style=" border: 2px solid red"
    }else if(password !== ConfirmPassword){
      dispatch({type: "CREATE_NOTIFICATION", payload: {message: "Password and Confirm Password Should be same.", type: "error"}})

    }
    // console.log(newuser);
   
  };

  const onstonSuggestClick = () => {
    // var el_down = document.getElementById("geeks");

    /* Function to generate combination of password */

    const firstName = formRef.current.first_name.value;
    const lastName = formRef.current.last_name.value;

    if (firstName && lastName) {
      formRef.current.password.value = genPassword;
      formRef.current.cpassword.value = genPassword;
      navigator.clipboard.writeText(genPassword);

      setSuggestedPassword(false);
    }
  };

  const displaySuggestedPassword = (e) => {
    // console.log(e.target.id)
    if(e.target.id === "password"){
    if (formRef.current.first_name.value && formRef.current.last_name.value && formRef.current.password.value !== genPassword) {
      if (!genPassword) {
        const firstName = formRef.current.first_name.value;
        const lastName = formRef.current.last_name.value;
        let pass = "";
        const str =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZ" +
          firstName +
          "abcdefghijklmnopqrstuvwxyz0123456789@#$" +
          lastName;

        for (let i = 1; i <= 8; i++) {
          const char = Math.floor(Math.random() * str.length + 1);

          pass += str.charAt(char);
        }
        setGenPassword(pass);
      }

      if(suggestedRef.current){
        suggestedRef.current.style = "display: flex !important"
      }
      

    }
  }else{
  //  console.log("hero to see")
    // if(suggestedPassword){
      if(suggestedRef.current){
      suggestedRef.current.style = "display: none !important"
    // setSuggestedPassword(false)
    // }
      }
  }
  };

  
  return (
    <div className="newuser-container" >
      <div className="newuser" >
        <div className="newuser-title">
          <span>ADD USER DETAILS</span>
        </div>
        <form
          id="form"
          autoComplete="off"
          className="newuser-form"
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <div className="newuser-form-first_name">
            <label htmlFor="first_name">First Name</label>
            <input autoComplete="off" type="text" id="first_name" required />
          </div>
          <div className="newuser-form-name">
            <label htmlFor="last_name">Last Name</label>
            <input autoComplete="off" type="text" id="last_name" required />
          </div>
          <div className="newuser-form-email">
            <label htmlFor="email">Email</label>
            <input autoComplete="off" type="email" id="email" required />
          </div>
          <div className="newuser-form-usertype">
            <label htmlFor="usertype">User Type</label>
            <select name="usertype" required>
              <option value="" disabled selected hidden>
                Select User Type
              </option>
              <option value="Admins">Admin</option>
              <option value="Maintenance User">Maintenance User</option>
            </select>
          </div>
          <div className="newuser-form-password"   >
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              required
              autoComplete="off"
              // onFocus={displaySuggestedPassword}
              // onBlur={(e)=>console.log(e.)}
            />
           
              <div
              ref={suggestedRef}
                className="newuser-form-password-suggested"
                onClick={onstonSuggestClick}
               
              >
                <div className="newuser-form-password-suggested-top">
                  <span>Use suggested password</span> <span>{genPassword}</span>
                </div>
                <div className="newuser-form-password-suggested-bottom">
                  <span>
                    A strong password needs to have a upper case letter , a
                    lower case letter , a number and a special character .
                  </span>
                </div>
              </div>
            
          </div>
          <div className="newuser-form-cpassword">
            <label htmlFor="cpassword">Confirm Password</label>
            <input type="password" id="cpassword" autoComplete="off" required />
          </div>
        </form>
        <button form="form" className="newuser-btn">
          CREATE USER
        </button>

        {newUserInfo.error && (
          <div className="newuser-popup newuser-popup-red">
            {newUserInfo.error}
            <span onClick={() => dispatch(ResetUser())}>+</span>
          </div>
        )}
        {newUserInfo.success && (
          <div className="newuser-popup newuser-popup-green">
            {newUserInfo.success}
            <span onClick={() => dispatch(ResetUser())}>+</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddNewUser;
