import React, { useEffect, useState } from "react";
import "../styles/dashboard.scss";
import Chart from "../components/Chart/Chart";
import DougnetChart from "../components/Chart/DougnetChart";
import UploadSvg from "../components/assets/svg/uploadSvg.svg";
import DownloadSvg from "../components/assets/svg/DownloadSvg.svg"
import { useDispatch, useSelector } from "react-redux";
import {
  getConversation,
  getCSVFile,
  get_faq_csv,
  get_feedback_csv,
  get_user_logs_csv,
  GetFrequentlyQuestionContext,
  getRatingsContext,
  GetSatisfactionContext,
  GetUntaggedQuestionContext,
  GetUserFeedback,
  updateFeedbackStatus,
  updateUntaggedStatus,
} from "../actions/DashboardAction";

import Table from "../components/Tables/Table";
import { getCsvTemplet, getIsinData, updateisin, uploadFile } from "../actions/ApiCall";
const DashBoard = () => {
  const dispatch = useDispatch();
  const { untaggedQuestion } = useSelector((state) => state.untaggedQuestion);
  const { FrequentlyQuestion } = useSelector(
    (state) => state.frequentlyQuestion
  );
  const { userFeedback } = useSelector((state) => state.userFeedback);
  const { allRatings } = useSelector((state) => state.getallRatings);
  const { persentageData } = useSelector((state) => state.persentageContext);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { conversations } = useSelector((state)=> state.conversations);
  const [selectedTab, setSelectedTab] = useState("faq")
  const [isinData, setIsinData] = useState();

  useEffect(() => {
    dispatch(GetUntaggedQuestionContext(null));
    dispatch(GetUserFeedback(null));
    dispatch(GetFrequentlyQuestionContext());
    dispatch(getRatingsContext());
    dispatch(GetSatisfactionContext());
    dispatch(getConversation(null))
    handleIsin();
  }, []);

  const handleIsin = (url, type) => {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    getIsinData(config, url, type).then((data) => {
      setIsinData(data);
    });
  };

  const handleupload = (formData, setShowBtn) => {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    uploadFile(config, formData)
      .then((data) => {
        // console.log(data)
        dispatch({
          type: "CREATE_NOTIFICATION",
          payload: { message: data.data, type: "success" },
        });
        handleIsin();
        setShowBtn(true);
      })
      .catch((e) => {
        console.log(e);
        setShowBtn(true);
        dispatch({
          type: "CREATE_NOTIFICATION",
          payload: { message: "Something went wrong.", type: "error" },
        });
      });
  };

  const handleEdit = (editedData,setEditIndex) =>{
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    console.log(editedData)
    const {branch, client, isin, status,data} = editedData;
    if(branch || client || isin || status){
      const id = data.id
      const updateData = {
        branch: branch ? branch : data.branch,
        client: client ? client : data.client,
        isin: isin ? isin : data.isin,
        status: status ? status : data.status
      }
      updateisin(config,updateData,id).then((res)=>{
        console.log(res)
        if(res.id){
        setIsinData((allPrevisin)=>({...allPrevisin,results: allPrevisin.results.map((item)=> item.id === res.id ? res : item)}))
        dispatch({type: "CREATE_NOTIFICATION", payload: {message: `Successfully updated ${res.client}.`, type: "success"}})
        setEditIndex()
        }
      })
      
    }else{
      setEditIndex()
    }
    
  }

  const dataset = [
    { rating: "1 star", val: 0 },
    { rating: "2 star", val: 0 },
    { rating: "3 star", val: 0 },
    { rating: "4 star", val: 0 },
    { rating: "5 star", val: 0 },
  ];

  const TableTabs =  [
    {tname:"Frequently Asked Question", id: "faq"},
    {tname:"ISIN Data", id: "isin"},
    {tname:"Untagged Question", id: "untagge"},
    {tname:"User Feedback", id: "feedback"},
    {tname:"User Logs", id: "userlogs"}
  ]

  const downloadtemplethandler = () => {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    getCsvTemplet(config).then((e)=>console.log(e))
    // console.log("working the test");
  };

  return (
    <div className="dashboard">
      {/* {console.log(allRatings)} */}
      <div className="dashboard-container">
        <div className="dashboard-chart">
          {/* {console.log(userFeedback)} */}
          <div className="dashboard-chart-top">
            <span>RATINGS GRAPH</span>
          </div>
          <span className="dashboard-chart-rating">
            Average rating - {allRatings ? allRatings.ratings[5].val : 0} star
          </span>
          <Chart dataset={allRatings ? allRatings.ratings : dataset} />
        </div>
        <div className="dashboard-dougnet">
          <div className="dashboard-dougnet-top">
            <span className="dashboard-dougnet-top-text1">CUSTOMER GRAPH</span>
            <span className="dashboard-dougnet-top-text2">
              <span></span> Satisfied Customers
            </span>
          </div>
          <div className="dashboard-dougnet-container">
            {persentageData && (
              <DougnetChart
                data={persentageData.percentage}
                text={persentageData ? persentageData.percentage : 0}
              />
            )}
          </div>
        </div>
      </div>
        <div className="dashboard-table-tabs">
              {TableTabs.map((e,el)=>{
                return <span onClick={()=>setSelectedTab(e.id)} className={`dashboard-table-tabs-item ${selectedTab === e.id && "dashboard-table-tabs-item-active"}`} key={e.id}>{e.tname}</span>
              })}
        </div>
        {(selectedTab === "faq" && FrequentlyQuestion) && (
        <Table
          title="FREQUENTLY ASKED QUESTIONS"
          HeadingOption={[{ name: "Question" }, { name: "Frequency" }]}
          EnableCSVExport={true}
          hanldeCSVFileAction={get_faq_csv}
          handlePaginateAction={GetFrequentlyQuestionContext}
          current={FrequentlyQuestion.links.current}
          previous={FrequentlyQuestion.links.previous}
          next={FrequentlyQuestion.links.next}
          DataOption={FrequentlyQuestion.results.map((e, el) => {
            return [
              { value: e.context_name, class: "frequently-name" },
              { value: e.frequency },
            ];
          })}
        />
      )}
        {(selectedTab === "isin" &&  isinData )&& (
        <Table
          title="ISIN DATA"
          HeadingOption={[
            { name: "Branch" },
            { name: "Client", type: "sort", class: "isin-client" },
            { name: "Status" },
            // { name: "Date", type: "sort" },
            { name: "CIN", class: "isin-cin_no"},
            { name: "Isin", class: "isin-isin_no" },
          ]}
          handlePaginateAction={handleIsin}
          current={isinData.links.current}
          previous={isinData.links.previous}
          EnableSearch={true}
          handleSearchAction={handleIsin}
          handleFileUploadAction={handleupload}
          // EnableDatePicker={true}
          handleEditAction={handleEdit}
          EnableTitleInfo={true}
          titleinfo={[
            { title: "Total count:", value: isinData.count },
            {
              title: "Last uploaded date:",
              value: isinData.links.last_updated.isin
                .split(".")[0]
                .replace("T", " "),
            },
            {
              title: "Total visits:",
              value: isinData.links.total_visits.frequency,
            },
          ]}
          EnableFileUpload={true}
          EnableSimpleBtn={true}
          uploadOption={{
            title: "Upload xlsx File",
            name: "Upload xlsx",
            // handler: uploadCsvHandler,
            icon: UploadSvg,
          }}
          btnOption={{
            title: "Download xlsx Template",
            name: "Download Template",
            handler: downloadtemplethandler,
            icon: DownloadSvg,
          }}
          next={isinData.links.next}
          DataOption={isinData.results.map((e, el) => {
            return [
              { value: e.branch_name, editType: "dropdown", dropdown:[
                {type:"linkintime", val:1},
                {type:"tcplindia", val:2},
                {type:"unisec", val: 3},
                {type:"skdc-consultants", val:4} ], id:e.id },
              { value: e.client, class: "isin-client", editType: "input"},
              { value: e.status, editType: "dropdown", dropdown:[{type:"Active",val: "Active"}, {type:"Inactive", val:"Inactive"}, {type:"Temporary", val:"Temporary"}] },
              { value: e.cin_number != 'nan' ? e.cin_number : "", class: "isin-cin_no" },
              { value: e.isin, class:"isin-isin_no", type: "editable", editType: "input", data: e },
            ];
          })}
        />
      )}
        {(selectedTab === "untagge" && untaggedQuestion) && (
        <Table
          title="UNTAGGED QUESTIONS"
          HeadingOption={[
            { name: "Question" },
            { name: "Date", type: "sort", title: "Sort by date" },
            { name: "Time" },
            { name: "Frequency", type: "sort", title: "Sort by Frequency" },
            { name: "Mail Click" },
            { name: "Status" },
          ]}
          DataOption={untaggedQuestion.results.map((e, el) => {
            return [
              {
                value: e.un_taged_question,
                type: "click",
                resolved: e.is_resolved,
                id: e.id,
              },
              { value: e.created_at.split("T")[0] },
              { value: e.created_at.split("T")[1].split(".")[0] },
              { value: e.frequency },
              { value: e.mail_click ? "True" : "False" },
              { value: e.is_resolved, type: "status", resolved: e.is_resolved },
            ];
          })}
          current={untaggedQuestion.links.current}
          previous={untaggedQuestion.links.previous}
          next={untaggedQuestion.links.next}
          EnableDatePicker={true}
          EnableCSVExport={true}
          // EnableSearch={true}
          handlePaginateAction={GetUntaggedQuestionContext}
          handleStatusAction={updateUntaggedStatus}
          hanldeCSVFileAction={getCSVFile}
        />
      )}
        {(selectedTab === "feedback" && userFeedback) && (
        <Table
          title="USER FEEDBACK"
          HeadingOption={[
            { name: "Reason" },
            { name: "User Feedback" },
            { name: "Date" },
            { name: "Status" },
          ]}
          current={userFeedback.links.current}
          previous={userFeedback.links.previous}
          next={userFeedback.links.next}
          EnableDatePicker={true}
          EnableCSVExport={true}
          hanldeCSVFileAction={get_feedback_csv}
          handlePaginateAction={GetUserFeedback}
          handleStatusAction={updateFeedbackStatus}
          DataOption={userFeedback.results.map((e, el) => {
            return [
              {
                value: e.reason,
                type: "click",
                resolved: e.is_resolved,
                id: e.id,
              },
              { value: e.description },
              { value: (e.created_at.split(".")[0]).replace("T", "  ") },
              { value: e.is_resolved, type: "status", resolved: e.is_resolved },
            ];
          })}
        />
      )}
        {(selectedTab === "userlogs" && conversations) && (
        <Table
          title="USER LOGS"
          HeadingOption={[
            { name: "Started", class: "width-20" },
            { name: "session Id", class: "width-35" },
            { name: "Selected Options", class: "width-35" },
          ]}
          current={conversations.links.current}
          previous={conversations.links.previous}
          next={conversations.links.next}
          EnableDatePicker={true}
          EnableTitleInfo={true}
          showTitleDate={true}
          titleinfo={[
            { title: "Total users:", value: conversations.details.details.total_users },
            {
              title: "Active users:",
              value: conversations.details.details.active_users,
            },
            {
              title: "Inactive users:",
              value: conversations.details.details.inactive_users,
            },
          ]}
          EnableCSVExport={true}
          handlePaginateAction={getConversation}
          // handleStatusAction={updateFeedbackStatus}
          hanldeCSVFileAction={get_user_logs_csv}
          DataOption={conversations.results.map((e, el) => {
            const options = []
            e.selected_options.forEach(ele => {
              if(ele){
                options.push(ele)
              }
              
            });
            return [
              {
                value: (e.started_at.split(".")[0]).replace("T", "  "), class: "width-20" },
              { value: e.session_id, class: "width-35" },
              { value: options, type: "number_list", class: "width-35" },
              // { value: e.is_resolved, type: "status", resolved: e.is_resolved },
            ];
          })}
        />
      )}

    
    </div>
  );
};

export default DashBoard;
