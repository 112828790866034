import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ".././styles/header.scss";
import LogoutSVG from "./assets/svg/logoutSVG.svg";
import { logout } from '../actions/UserLoginActions';
import dasboardEmoji from "./assets/svg/dashboard.svg";
import homeEmoji from "./assets/svg/home.svg";
import workflowEmoji from "./assets/svg/workflow.svg";
import newuserEmoji from "./assets/svg/newuser.svg";
import LogsEmoji from "./assets/svg/logs.svg";

const Header = () => {

    const [title, setTitle] = useState("");
    const [emoji, setEmoji] = useState();
    const {userInfo} = useSelector(state=>state.userLogin)
    const location = useLocation()
    const currentLocation = location.pathname.replace("/", "");
    const dispatch = useDispatch()

useEffect(()=>{
    setTitle(currentLocation.toUpperCase())
    if(currentLocation === "home"){
        setTitle("Welcome back, "+userInfo.first_name+" ")
        setEmoji(homeEmoji)
    }else if(currentLocation === "newuser"){
        setEmoji(newuserEmoji)
        setTitle("ADD NEW USER")
    }else if(currentLocation === "workflow"){
        setEmoji(workflowEmoji);
    }else if(currentLocation === "dashboard"){
        setEmoji(dasboardEmoji)
    }else if(currentLocation === "deletelogs"){
        setTitle("DELETED LOGS")
        setEmoji(LogsEmoji)
    }
},[currentLocation, userInfo])
    
  return (
    <div className='header'>
        <div className='header-title'><span>{title}</span> <img src={emoji} alt='emoji'/></div> 
        <div className='header-logout' onClick={()=>dispatch(logout())}> <span className='header-logout-btn'> Logout</span> <img src={LogoutSVG} alt="logout" /></div>
    </div>
  )
}

export default Header