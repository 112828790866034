import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import "../styles/notification.scss"
const Notification = () => {
    const {status} = useSelector(state=> state.Notification)
    const dispatch = useDispatch()
    useEffect(()=>{
        if(status){
            setTimeout(() => {
                dispatch({type: "CREATE_NOTIFICATION_RESET"})
            }, 5000);
        }
       
        
    }, [status])
  return (
      <>
    {status &&<div className='notification'>
        <span className={`notification-popup ${status.type === "error"? "notification-popup-error": "notification-popup-success" }`}>{status.message} <span>+</span></span>
    </div>}
    </>
  )
}

export default Notification