const initailState = {
    question: {}
}

export const WorkflowReducers = (state=initailState, action) => {

    switch (action.type) {
        case "GET_WORKFLOW_REQUEST": 
            return { loading: true}

        case "GET_WORKFLOW_SUCCESS": 
            return {loading: false, question: action.payload}

        case "GET_WORKFLOW_FAIL":
            return {loading: false, error: action.payload}

        case "GET_WORKFLOW_RESET":
            return {}

        default:
            return state
    }
}

export const UpdateWorkflowReducer = (state={},action) =>{
    switch (action.type) {
        case "UPDATE_WORKFLOW_REQUEST": 
            return { loading: true}

        case "UPDATE_WORKFLOW_SUCCESS": 
            return {loading: false, data: action.payload}

        case "UPDATE_WORKFLOW_FAIL":
            return {loading: false, error: action.payload}

        case "UPDATE_WORKFLOW_RESET":
            return {}

        default:
            return state
    }
}

export const cREATEWorkflowReducer = (state={},action) =>{
    switch (action.type) {
        case "CREATE_WORKFLOW_REQUEST": 
            return { loading: true}

        case "CREATE_WORKFLOW_SUCCESS": 
            return {loading: false, data: action.payload}

        case "CREATE_WORKFLOW_FAIL":
            return {loading: false, error: action.payload}

        case "CREATE_WORKFLOW_RESET":
            return {}

        default:
            return state
    }
}


export const WorkflowEditReducers = (state={}, action) => {

    switch (action.type) {
        case "GET_WORKFLOWEDIT_REQUEST": 
            return { loading: true}

        case "GET_WORKFLOWEDIT_SUCCESS": 
            return {loading: false, EditWorkflow: action.payload}

        case "GET_WORKFLOWEDIT_FAIL":
            return {loading: false, error: action.payload}

        case "GET_WORKFLOWEDIT_RESET":
            return {}

        default:
            return state
    }
}