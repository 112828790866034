import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Login from "./pages/Login";
import SideNav from "./components/SideNav/SideNav";
import "./styles/App.scss";
import Home from "./pages/Home";
// import { Route } from "react-router-dom/cjs/react-router-dom.min";
import ForgetPassword from "./pages/ForgetPassword";
import { Switch, Route } from "react-router-dom";

const App = () => {
  const { userInfo } = useSelector((state) => state.userLogin);

  return (
    <div className="App">
      {!userInfo ? (
        <>
          <Switch>
            <Route exact path="/reset-password/:token">
              <ForgetPassword />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>

            <Redirect to="/login" />
          </Switch>
        </>
      ) : (
        <>
          <SideNav />
          <Home />
        </>
      )}
    </div>
  );
};
// http://127.0.0.1:8000
export default App;
