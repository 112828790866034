export const LoginReducers = (state={}, action) => {

    switch (action.type) {
        case "USER_LOGIN_REQUEST": 
            return { loading: true}

        case "USER_LOGIN_SUCCESS": 
            return {loading: false, userInfo: action.payload}

        case "USER_LOGIN_FAIL":
            return {loading: false, error: action.payload}

        case "USER_LOGOUT":
            return {}

        default:
            return state
    }
}


export const NewUserReducers = (state={}, action) => {

    switch (action.type) {
        case "CREATE_USER_REQUEST": 
            return { loading: true}

        case "CREATE_USER_SUCCESS": 
            return {loading: false, newUserInfo: action.payload, success:"User Created successfully"}

        case "CREATE_USER_FAIL":
            return {loading: false, error: action.payload}

        case "CREATE_USER_RESET":
            return {}

        default:
            return state
    }
}