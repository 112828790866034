import React, { useEffect, useRef, useState } from "react";
// import { changeNodeAtPath } from 'react-sortable-tree';
import AnswerComponent from "./AnswerComponent";
import Model from "../Model/WorkFlowModel";
import "../../styles/TitleComponent.scss";
import DeleteSvg from "../assets/svg/DeleteSVG.svg";
import EditSvg from "../assets/svg/EditSVG.svg";
import ExpandSVG from "../assets/svg/ExpandSVG.svg";
import CopySVG from "../assets/svg/copy.svg";
import DeleteModel from "../Model/DeleteModel";

const TitleComponent = ({
  node,
  path,
  getNodeKey,
  selectThis,
  insertNewNode,
  isSearchFocus,
  isSearchMatch,
  removeNode,
  updateNode,
  addNodeUnderParent,
  allMatchetreeIndex,
  treeIndex,
  searchFocusIndex,
}) => {
  const [model, setModel] = useState(false);
  const [addModel, setAddModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [copyModel, setCopyModel] = useState(false);
  const [isfound, setIsFounded] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    let e = formRef.current;
    let isFounded = false;
    // console.log(allMatchetreeIndex[searchFocusIndex],treeIndex)
    allMatchetreeIndex.map((e) => {
      if (e === treeIndex) {
        isFounded = true;
      }
    });

    if (isFounded) {
      e.style = "border: 3px solid #f5ca8a";
      setIsFounded(true);
      e.classList.remove("rst__rowSearchMatch", "rst__rowSearchFocus");
      //  console.log(allMatchetreeIndex[searchFocusIndex], treeIndex)

      // e.scrollIntoView({block: 'center'})
    } else {
      // e.classList.remove("rst__rowSearchFocus")
      e.classList.remove("rst__rowSearchMatch", "rst__rowSearchFocus");
      e.style = "border: none";
    }
    if (allMatchetreeIndex[searchFocusIndex] === treeIndex) {
      e.style = "border: 3px solid blue";
    }
    // console.log(isFounded)
    while (!e.classList.contains("rst__node")) {
      if (e.classList.contains("rst__rowContents")) {
        e.parentNode.children[0].innerHTML = `<span class="questionNumber">Q.<span>`;
      }
      e = e.parentElement;
    }
    e.style.height = node.ISexpanded ? "300px" : "62px";
    //  console.log(isSearchMatch)
  }, [node, allMatchetreeIndex]);

  const handleExpand = () => {
    // console.log(path)
    console.log("handle Exapnd");
    updateNode(node, path, { ISexpanded: !node.ISexpanded }, getNodeKey);
    // console.log(node)
    const number = path.map((number, el) => {
      if (el === 0) {
        return node.QuestionNumber;
      }
      if (el !== 0) {
        return number - path[el - 1];
      }
      return number;
    });
    // console.log(number)
  };

  const updateQuestion = (updateObj) => {
    updateNode(node, path, updateObj, getNodeKey);
  };

  const closeModel = () => {
    setModel(false);
    setCopyModel(false);
    setDeleteModel(false);
  };
  const closeAddModel = () => {
    setAddModel(false);
  };

  return (
    <div
      className="TitleComponent"
      style={{ fontWeight: "400" }}
      id={treeIndex}
    >
      <form
        ref={formRef}
        className={
          node.children && node.children.length ? "form form-parent" : "form"
        }
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          selectThis(node, path);
        }}
      >
        <div className="form-title">
          {node.question.length > 195
            ? node.question.substring(0, 195) + "..."
            : node.question}
        </div>
        <div className="form-btns">
          {/* {console.log(node)} */}
          {(!node.children ||
            (node.children && node.children.length === 0)) && (
            <span
              className="TitleComponent-copy"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setCopyModel(true);
              }}
            >
              <img src={CopySVG} alt="Copy" title="Copy" />
            </span>
          )}

          {node.children && node.children.length !== 0 && (
            <span
              title="Add"
              className="TitleComponent-add"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setAddModel(true);
              }}
            >
              <span>+</span>Add
            </span>
          )}
          {(!node.children ||
            (node.children && node.children.length === 0)) && (
            <span
              className="TitleComponent-remove"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDeleteModel(true);
              }}
            >
              <img src={DeleteSvg} alt="Delete" title="Delete" />
            </span>
          )}
          {node.answer && (
            <span
              className="TitleComponent-edit"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setModel(true);
              }}
            >
              <img
                title="Edit"
                className={
                  node.children && node.children.length !== 0 && "form-p"
                }
                src={EditSvg}
                alt="edit"
              />
            </span>
          )}
          {node.answer && (
            <span className="TitleComponent-expand" onClick={handleExpand}>
              <img
                title="Expand"
                className={
                  node.children && node.children.length !== 0 && "form-p"
                }
                src={ExpandSVG}
                alt="expand"
              />
            </span>
          )}
        </div>
      </form>
      {node.ISexpanded && <AnswerComponent answer={node.answer} />}
      {model && (
        <Model
          node={node}
          closeModel={closeModel}
          updateQuestion={updateQuestion}
        />
      )}
      {copyModel && (
        <Model
          path={path}
          node={node}
          insertNewNode={insertNewNode}
          uuid={node.uuid}
          closeModel={closeModel}
          updateQuestion={updateQuestion}
          copy={true}
        />
      )}
      {addModel && (
        <Model
          closeModel={closeAddModel}
          uuid={node.uuid}
          path={path}
          insertNewNode={addNodeUnderParent}
        />
      )}
      {deleteModel && (
        <DeleteModel
          closeModel={closeModel}
          path={path}
          removeNode={removeNode}
          node={node}
        />
      )}
    </div>
  );
};

export default TitleComponent;
