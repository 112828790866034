const initialValue = {
    status: null
}

export const NotificationReducers = (state = initialValue, action) => {

    switch (action.type) {
        // case "CREATE_USER_REQUEST": 
        //     return { loading: true}

        case "CREATE_NOTIFICATION": 
            return {status: action.payload}

        case "CREATE_NOTIFICATION_RESET":
            return {status: null}

        default:
            return state
    }
}