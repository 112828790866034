import { errorHandler } from "./UtilsActions";
import axios from "axios";
import url  from '../webconfig.json'
export const GetAnalyticsContext = (filter) => async (dispatch, getState) => {
    try{

        dispatch({ 
            type: "GET_ANALYTICS_REQUEST"
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers:{
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.access}`
            }
        };
        
        if(filter && filter.filter !== "All"){
            const {data} = await axios.get(
                `${url.proxy}/account/analytics/?${filter}`,config
                )
                // console.log(data)
            dispatch({
                type: "GET_ANALYTICS_SUCCESS",
                payload: data
            })
        }else{
            const {data} = await axios(
                `${url.proxy}/account/analytics/`
                )
                // console.log(data)
            dispatch({
                type: "GET_ANALYTICS_SUCCESS",
                payload: data
            })
    
        }
       
        // dispatch(users())

    }catch(error){
        errorHandler(dispatch, error, "GET_ANALYTICS_FAIL")
      
    }
};