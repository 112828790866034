const initailState = {
    data:null
}

export const AnalyticsReducers = (state=initailState, action) => {

    switch (action.type) {
        case "GET_ANALYTICS_REQUEST": 
            return { loading: true}

        case "GET_ANALYTICS_SUCCESS": 
            return {loading: false, data: action.payload}

        case "GET_ANALYTICS_FAIL":
            return {loading: false, error: action.payload}

        case "GET_ANALYTICS_RESET":
            return {}

        default:
            return state
    }
}