import axios from "axios";
import url  from '../webconfig.json'
import { errorHandler } from "./UtilsActions";
export const login = (email, password) => async (dispatch) => {
    try{

        dispatch({
            type: "USER_LOGIN_REQUEST"
        })

        const config = {
            headers:{
                'Content-type': 'application/json'
            }
        };

        const {data} = await axios.post(
            `${url.proxy}/account/login/`, 
            {'email': email, 'password': password},
            config
            )

        dispatch({
            type: "USER_LOGIN_SUCCESS",
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

    }catch(error){
        // console.log(error)
        // if(error && error.response && error.response.status === 401){
            dispatch({
                type: "USER_LOGIN_FAIL",
                payload: (error.response && error.response.data.detail
                    ? error.response.data.detail 
                    : error.message)
            })
            setTimeout(() => {
                dispatch(logout())
              }, 5000);
           
        // }else{
        //     errorHandler(dispatch, error, "USER_LOGIN_FAIL")
        // }
    }
} 


export const logout = () => (dispatch) => {
    localStorage.removeItem("userInfo");
    dispatch({ type: "USER_LOGOUT" });
  }

  export const ForgetPassword = (email, userRedirect) => async (dispatch) => {
    try{

        // dispatch({
        //     type: "USER_RESET_REQUEST"
        // })

        const config = {
            headers:{
                'Content-type': 'application/json'
            }
        };

        const {data} = await axios.post(
            `${url.proxy}/account/forgot-password/`, 
            {'email': email},
            config
            )
            if(typeof(data) === "string"){
                dispatch({type: "CREATE_NOTIFICATION", payload: {message: data, type: "success"}})
                userRedirect(true)
            }

        // dispatch({
        //     type: "USER_RESET_SUCCESS",
        //     payload: data
        // })

        // localStorage.setItem('userInfo', JSON.stringify(data))

    }catch(error){
        console.log(error.response)
        userRedirect()
        if(error.response){
        if(error.response.status === 500){
            dispatch({type: "CREATE_NOTIFICATION", payload: {message:"Something Wrong Happend", type: "error" }})
        }else if(error.response.status === 400){
            dispatch({type: "CREATE_NOTIFICATION", payload: {message:"No user found with given mail id.", type: "error"}})
        }else{
            dispatch({type: "CREATE_NOTIFICATION", payload: {message:"Something Wrong Happend",type: "error"}})
        }
    }
        errorHandler(dispatch, error, "USER_RESET_FAIL")
        
    }
} 



export const ResetPassword = (postdata,token,userRedirect, setBtnDisabled) => async (dispatch) => {
    try{

        // dispatch({
        //     type: "USER_RESET_REQUEST"
        // })
setBtnDisabled(true)
        const config = {
            headers:{
                'Content-type': 'application/json'
            }
        };

        const {data} = await axios.post(
            `${url.proxy}/account/reset-password/${token}/`, 
            postdata,
            config
            )
            if(typeof(data) === "string"){
                dispatch({type: "CREATE_NOTIFICATION", payload: {message: data, type: "success"}})
                userRedirect()
                
            }

        // dispatch({
        //     type: "USER_RESET_SUCCESS",
        //     payload: data
        // })

        // localStorage.setItem('userInfo', JSON.stringify(data))

    }catch(error){
        console.log(error)
        errorHandler(dispatch, error, "USER_RESET_FAIL")
        setBtnDisabled(false)
        
    }
} 