import axios from "axios";
import url from "../webconfig.json";
import { errorHandler, FileDownload, FileDownloadGet, getApiCall } from "./UtilsActions";

export const GetUntaggedQuestionContext = (type,date,requestData) => async (dispatch, getState) => {
  // console.log(data,type, date)
    try {
      // dispatch({
      //   type: "GET_DASHBOARD_REQUEST",
      // });

      let URL = `${url.proxy}/account/dashboard-untagged-questions/`;
      const data = await getApiCall(URL, type, date)
      dispatch({ type: "GET_DASHBOARD_SUCCESS", payload: data});
    } catch (error) {
      errorHandler(dispatch, error, "GET_DASHBOARD_FAIL");
    }
  };
export const GetUserFeedback = (type,date,data) => async (dispatch, getState) => {
  // console.log(type,date,data)
    try {
      // dispatch({
      //   type: "GET_USERFEEDBACK_REQUEST",
      // });
        let URL = `${url.proxy}/chatbot/feedback/`;
        const data = await getApiCall(URL,type,date)
      dispatch({
        type: "GET_USERFEEDBACK_SUCCESS",
        payload: data,
      });
    } catch (error) {
      errorHandler(dispatch, error, "GET_USERFEEDBACK_FAIL");
    }
  };

export const GetFrequentlyQuestionContext = (URLaddres) => async (dispatch, getState) => {
    try {
      // dispatch({
      //   type: "GET_FREQUENTLY_REQUEST",
      // });

      const URL =  `${url.proxy}/account/dashboard-frequent-questions/`
      const data = await getApiCall(URL, URLaddres)
        dispatch({
          type: "GET_FREQUENTLY_SUCCESS",
          payload: data,
        });
     
    } catch (error) {
      errorHandler(dispatch, error, "GET_FREQUENTLY_FAIL");
    }
  };

export const GetAllUserContext = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: "GET_ALLUSER_REQUEST",
    });
    const URL = `${url.proxy}/account/search-userlogs/`
    const data = await getApiCall(URL)
    dispatch({
      type: "GET_ALLUSER_SUCCESS",
      payload: data,
    });

  
  } catch (error) {
    errorHandler(dispatch, error, "GET_ALLUSER_FAIL");
  }
  };

export const getCSVFile = (data, date) => async (dispatch, getState) =>{
  const URL =  data ? `${url.proxy}/account/export-untagged-questions/?${data}` : `${url.proxy}/account/export-untagged-questions/`
  const name =  `${data ? date.start+"_to_"+date.end :"All"}_Untagged_Question.xlsx`
  FileDownloadGet(URL,name)
}

export const get_faq_csv = (data, date) => async (dispatch, getState) =>{
  const URL =  data ? `${url.proxy}/account/export-faq/?${data}` : `${url.proxy}/account/export-faq/`
  const name =  `FAQ_count.xlsx`
  FileDownloadGet(URL,name)
}

export const get_feedback_csv = (data, date) => async (dispatch, getState) =>{
  const URL =  data ? `${url.proxy}/account/export-user-feedback/?${data}` : `${url.proxy}/account/export-user-feedback/`
  const name =  `${data ? date.start+"_to_"+date.end :"All"}_User_Feedback.xlsx`
  FileDownloadGet(URL,name)
}

export const get_user_logs_csv = (data, date) => async (dispatch, getState) =>{
  const URL =  data ? `${url.proxy}/account/export-user-logs/?${data}` : `${url.proxy}/account/export-user-logs/`
  const name =  `${data ? date.start+"_to_"+date.end :"All"}_User_Logs.xlsx`
  FileDownloadGet(URL,name)
}

export const getRatingsContext = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: "GET_RATINGS_REQUEST",
    });
    const URL = ` ${url.proxy}/account/dashboard-ratings/`
    const data = await getApiCall(URL)
    dispatch({
      type: "GET_RATINGS_SUCCESS",
      payload: data,
    });

  } catch (error) {
    errorHandler(dispatch, error, "GET_RATINGS_FAIL");
  }
};

export const GetSatisfactionContext = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: "GET_SATISFACTION_REQUEST",
    });

    const URL =  ` ${url.proxy}/account/dashboard-customer-satisfaction-graph/`;
    const data  = await getApiCall(URL)
    dispatch({
      type: "GET_SATISFACTION_SUCCESS",
      payload: data,
    });

  } catch (error) {
    errorHandler(dispatch, error, "GET_SATISFACTION_FAIL");
  }
};



export const updateFeedbackStatus = (type, date, requestData) => async (dispatch, getState) => {
  console.log(type,date,requestData)
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    let URL = `${url.proxy}/chatbot/feedback/${requestData.id}/`;
    const { data } = await axios.patch(URL, {is_resolved: requestData.resolved},config);
    dispatch(GetUserFeedback(type,date));
  } catch (error) {
    errorHandler(dispatch, error, "GET_DASHBOARD_FAIL");
  }
};


export const updateUntaggedStatus = (type,date,requestData) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    let URL = `${url.proxy}/account/dashboard-untagged-questions/`;
    const { data } = await axios.patch(URL, requestData,config);
    dispatch(GetUntaggedQuestionContext(type,date));
  } catch (error) {
    errorHandler(dispatch, error, "GET_DASHBOARD_FAIL");
  }
};


export const getConversation = (type,date) => async (dispatch, getState) => {
  try {
    const URL =  ` ${url.proxy}/account/conversations/`;
    const data  = await getApiCall(URL, type, date)
    dispatch({
      type: "GET_CONVERSATIONS_SUCCESS",
      payload: data,
    });

  } catch (error) {
    errorHandler(dispatch, error, "GET_CONVERSATIONS_FAIL");
  }
};