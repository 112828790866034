import React, { useEffect, useState } from "react";
import "../styles/homePage.scss";
import LineImg from "../components/assets/svg/LineSvg.svg";
import SearchSVG from "../components/assets/svg/search.svg";
import { useDispatch, useSelector } from "react-redux";
import { GetAnalyticsContext } from "../actions/AnalyticsAction";
import { GetUserLogsContext } from "../actions/userLogsAction";
import { GetAllUserContext } from "../actions/DashboardAction";
import Autocomplete from "../components/AutoCompleteInput";
import ViewIcon from "../components/assets/svg/ExpandSVG.svg";
import  DatePicker  from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { NavLink } from "react-router-dom";
import Table from "../components/Tables/Table";
const HomePage = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.analytics);
  const { logs } = useSelector((state) => state.userLogs);
  const { allUser } = useSelector((state) => state.getallUser);
  const [viewModel, setViewModel] = useState(false);
  const [modelData, setModelData] = useState(null);
  const [ dateRange, setDateRange ] = useState([null, null]);
  const [ startDate, endDate ]   = dateRange;
  // const [ onSubmit, setOnSubmit ]   = useState(false)
  const [input, setInput] = useState("");
  useEffect(() => {
    dispatch(GetAnalyticsContext());
    dispatch(GetUserLogsContext());
    dispatch(GetAllUserContext());
  }, []);


  const handleClick = (val) => {
    if (val === null) {
      dispatch(GetUserLogsContext());
    } else {
      console.log(val);
      dispatch(GetUserLogsContext(null, { search_by_user: val }));
    }
    console.log("handle Click");
  };

  const TimeSelectHandler = (startDate, endDate) => {
    let start = startDate ? moment(startDate).format('YYYY-MM-DD') : null
    let end = endDate ? moment(endDate).format('YYYY-MM-DD')  : null
    // setOnSubmit(true)
    if(start && end){
      console.log(start, end)
      const date = `start_date=${start}&end_date=${end}`
      dispatch(GetAnalyticsContext(date));
    }
    
    // dispatch(getInterviewList(start, end))

}
  const handleModel = (e) => {
    console.log(e);
    setViewModel(true);
    setModelData(e);
  };
  return (
    <div className="homepage">
      <div className="homepage-analytics">
        <div className="homepage-analytics-top">
          <div className="homepage-analytics-top-left">
            <span>ANALYTICS</span>
            <img src={LineImg} alt="line" />
          </div>
          <div className="homepage-analytics-top-right">
         
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                            selectsRange={true}
                            todayButton="Today"
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            onChange={(update) => {
                              // console.log(update)
                                setDateRange(update);
                                TimeSelectHandler(update[0],update[1])
                                // if (onSubmit){
                                //     // dispatch({ type: "LIST_INTERVIEW_RESET"})
                                //     setOnSubmit(false)
                                // }
                            }}
                            // maxDate={new Date()}
                            tabIndex={1}
                            placeholderText="Select date range..." 
                        />
                  
          </div>
        </div>
        <div className="homepage-analytics-bottom">
          <div>
            
              <span>Total visitors</span>
              <span className="homepage-analytics-bottom-num">
                {data ? data.visitors : 0}
              </span>
           
          </div>
          <div>
            <NavLink to="/dashboard">
              <span>Total questions and answer</span>
              <span className="homepage-analytics-bottom-num">
                {data ? data.question_answers : 0}
              </span>
            </NavLink>
          </div>
          <div>
            <NavLink to="/dashboard">
              {" "}
              <span>Total Untagged Question</span>
              <span className="homepage-analytics-bottom-num">
                {data ? data.untagged_questions : 0}
              </span>
            </NavLink>
          </div>
        </div>
      </div>

      <div className="homepage-input">
        <span>USER LOGS</span>{" "}
        <div className="homepage-input-container">
          <img src={SearchSVG} alt="search icon" />
          <Autocomplete
            handleClick={handleClick}
            setInput={setInput}
            placeholder="Search Username"
            suggestions={allUser ? allUser.search_by_user : []}
          />
        </div>
      </div>

          
      { logs && logs.results  && <Table 
      title=""
      HeadingOption={
        [
        {name: "Username"},
        {name: "Date"},
        {name: "Time"},
        {name: "Action"},
      ]}
      current={logs.links.current}
      previous={logs.links.previous}
      next={logs.links.next}
      // EnableDatePicker={true}
      // EnableCSVExport={true}
      handlePaginateAction={GetUserLogsContext}
      // handleStatusAction={updateFeedbackStatus}
      // hanldeCSVFileAction={getCSVFile}
      DataOption={logs.results.map(((e,el)=>{
        const actionArray = e.actions.split(" ")
        const actionType = actionArray[0]
        const actionString = actionArray.slice(1,actionArray.length)
        return [
          {value: e.user_name},
          {value: e.date},
          {value: e.time.split(".")[0]},
          {value: [actionType, actionString.join(" ")], class:"hompage-action", data: e, type: "action", func: handleModel},
        ]
      }))}
      />}
    

      {viewModel && (
        <div className="homepage-model">
          <span
            className="homepage-model-close"
            onClick={() => setViewModel(false)}
          >
            +
          </span>
          <div className="homepage-model-prev">
            <span className="homepage-model-prev-head">Previous</span>
            {modelData.history.previous_data.map((e, el) => {
              return (
                <div className="homepage-model-container">
                  <span className="homepage-model-container-heading">
                    {Object.keys(e)}
                  </span>
                  <span className="homepage-model-container-change"
                    dangerouslySetInnerHTML={{
                      __html:
                        (Object.values(e)[0] === null || !Object.values(e)[0])
                          ? "null"
                          : Object.values(e),
                    }}
                  ></span>
                </div>
              );
            })}
          </div>
          <div className="homepage-model-curr">
            <span className="homepage-model-curr-head">Current</span>
            {modelData.history.current_data.map((e, el) => {
              // const s = Object.values(e)
              // console.log(s[0])
              return (
                <div className="homepage-model-container">
                  <span className="homepage-model-container-heading">
                    {Object.keys(e)}
                  </span>
                  <span className="homepage-model-container-change"
                    dangerouslySetInnerHTML={{
                      __html:
                        Object.values(e)[0] === null 
                          ? "null"
                          : Object.values(e),
                    }}
                  ></span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
