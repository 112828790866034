import axios from "axios"
import url from "../webconfig.json";
import { FileDownload } from "./UtilsActions";
export const getContextDetails = async(uuid) =>{
    const userDetails = JSON.parse(localStorage.getItem("userInfo"))
    const config = {
        headers:{
            'Content-type': 'application/json',
            'Authorization': `Bearer ${userDetails.access}`
        }
    };

    const {data} = await axios.get(
        `${url.proxy}/account/admin-context-detail/${uuid}`,config
        )
        // console.log("in the api call",data)
        return data
}

export const getIsinData = async(config,query,type) =>{

    let URL = `${url.proxy}/chatbot/isin/`
    if(query){
        if(query.key){
            URL = URL+`${"?search="+query.key}`
        }else{
            URL = query
        }
       
    }
    if(type){
    URL = URL+`?order_by=${type}`
    }
    // console.log(type,query,config)
    const {data} = await axios.get(URL.includes("idia-backend.linkintime") ? URL.replace("http:", "https:") :URL, config)
    // console.log(data)
    return data
}

export const uploadFile = async(config,formdata)=>{
    const res = await axios.post(`${url.proxy}/account/data-load/`, formdata, config)
    return res
    // console.log(res)
}

export const updateisin = async(config, data, id)=>{
    const res = await axios.put(`${url.proxy}/chatbot/isin/${id}/`,data, config)
    return res.data
}

export const getCsvTemplet = async(config)=>{
 const URL =  `${url.proxy}/account/data-export/`
 const name = "isin_templet.xlsx"
  FileDownload(URL, name)
}