import { errorHandler } from "./UtilsActions";
import axios from "axios";
import url  from '../webconfig.json'
export const createNewUser = ( newUserData ) => async (dispatch, getState) => {
    try{

        dispatch({ 
            type: "CREATE_USER_REQUEST"
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers:{
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.access}`
            }
        };
        

        const {data} = await axios.post(
            `${url.proxy}/account/create-user/`,
            newUserData,
            config
            )

       
        console.log(data)
        if(data.email){
            dispatch({
                type: "CREATE_USER_FAIL",
                payload: data.email
            })
                    // dispatch({type: "CREATE_NOTIFICATION", payload: {message:data.email, type: "error" }})

        }else{
            dispatch({
                type: "CREATE_USER_SUCCESS",
                payload: data
            })
        }
        // dispatch({type: "CREATE_NOTIFICATION", payload: {message:error.response.data[0], type: "error" }})

        // dispatch(users())

    }catch(error){
        errorHandler(dispatch, error, "CREATE_USER_FAIL")

    }
};

export const ResetUser = () => (dispatch) => {
    
    dispatch({ type: "CREATE_USER_RESET" });
  }
