import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import ReactQuill from "react-quill";
import {Quill} from "react-quill"

import { useDispatch, useSelector } from "react-redux";
// import { getContextDetails } from "../../actions/ApiCall";
import { CopyContextWorkflow, CreateWorkflowContext, GetWorkflowEditContext, UpdateWorkflowContext } from "../../actions/WorkflowActions";
import "../../styles/model.scss";
import "react-quill/dist/quill.snow.css";
import DeleteSVG from "../assets/svg/DeleteSVG.svg";
import  EditorToolbar,{ formats, modules } from "./EditorToolbar";
const Model = (props) => {
  const { closeModel, updateQuestion, node, copy } = props;
  // const QuillRef = useRef();
  const [question, setQuestion] = useState("");
  const [answer, setAns] = useState("");
  const [keywords, setKeywords] = useState("");
  const [data, setData] = useState();
  const [file, setFile] = useState(null);
  const [url, seturl] = useState("");
  const [textUrl, setTextUrl] = useState(null);
  const [doc, setDocument] = useState();
  const dispatch = useDispatch();
  const {EditWorkflow} = useSelector(state=> state.EditWorkflow)
  const answerRef = useRef();
  const [charecter, setCharector] = useState(0);

  const [error, setError] = useState(null);

  useEffect(()=>{
    console.log(props.path)
    if(node){
    dispatch(GetWorkflowEditContext(node.uuid))
    }
  },[])
  useEffect(() => {
    // console.log(props.node)
   
if(node){
  // dispatch(GetWorkflowEditContext(node.uuid))
  // getContextDetails(node.uuid).then((data) => {
  //   console.log(data);
  //   setData(data);
  //   setAns(data.answer);
  //   setQuestion(data.question);
  //   setKeywords(data.keywords);
  //   if (data.url && data.url !== "null") {
  //     seturl(data.url);
  //   }
  //   if(data.document && data.document !== "null"){
  //     setDocument(data.document)
  //   }
  // });

  if(EditWorkflow){
    setData(EditWorkflow);
    setAns(EditWorkflow.answer);
    setQuestion(EditWorkflow.question);
    setKeywords(EditWorkflow.keywords);
    if (EditWorkflow.url && EditWorkflow.url !== "null") {
      seturl(EditWorkflow.url);
    }
    if(EditWorkflow.document && EditWorkflow.document !== "null"){
      setDocument(EditWorkflow.document)
    }else{
      setDocument()
    }
  }
}
   
  }, [EditWorkflow]);

  const answerHandler = (e)=>{
    console.log(answerRef)
    // console.log(e)
    // var text = Quill.getText();
    // console.log(text)
    // console.log(answerRef)

    // var quill = answerRef.current.editor;
    // console.log(quill)
    // quill.on("text-change", function (delta, old, source) {
      // console.log(quill.getLength()) 
      // setCharector(quill.selection.cursor.selection.lastRange.index)
       
    // });
    if(!e.replace("<p><br></p>",'')){
      setAns("")
    }else{
      setAns(e)
    }

    var text = document.getElementById("text");

    // When the user presses any key on the keyboard, run the function
    window.addEventListener("keyup", function(event) {
    
      // If "caps lock" is pressed, display the warning text
      if (event.getModifierState("CapsLock")) {
        text.style.display = "block";
      } else {
        text.style.display = "none"
      }
    });
      // console.log(e.replace("<p><br></p>",''))
      
    
    
  }
 
  const handleUpdate = (e) => {
    if(!answer){
      setError("Please Fill Out The Answer Field")
     }else if(!question){
       setError("Please Fill Out The Question Field")
     }else if(!keywords){
      setError("Please Fill Out The Keywords Field")
  
     }
    e.preventDefault()
    let keywor = keywords
      if(!keywords.includes(",")){
       keywor = keywords+","
      }
       let formData = new FormData();  
       formData.append("uuid",data.uuid)
       formData.append("document", file ? file : doc ? doc : "")
       formData.append("question", question)
       formData.append("answer", answer)
       formData.append("keywords", keywor)
       formData.append("url", url)
       formData.append("prev_context", data.prev_context ? data.prev_context : "")
       formData.append("texturl", textUrl)
      //  console.log(Array.from(formData));
    const updateNode = {
      ...data,
      question,
      answer,
      keywords,
     url: url ? url : "",
     document: file ? file : data.document,
    };
    

    
    if(answer && question && keywords){
    dispatch(UpdateWorkflowContext(data.uuid,formData))

    updateQuestion({ ...updateNode });

    closeModel();
    }
  };


  const handleFilesUpload = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      setFile(file);
    }
  };


  const handleCreateQuestion = (e) =>{
   if(!answer){
    setError("Please Fill Out The Answer Field")
   }else if(!question){
     setError("Please Fill Out The Question Field")
   }else if(!keywords){
    setError("Please Fill Out The Keywords Field")

   }
    e.preventDefault()
    const newQuestion = {
      question,
      prev_context: props.uuid,
      leaf_node: false,
      inputs_required: false,
       answer,
       company_name: "linkintime",
       document: file,
       url,
       keywords
    }

    if(answer && question && keywords){
      let keywor = keywords
      if(!keywords.includes(",")){
       keywor = keywords+","
      }
      let formData = new FormData();  
      // formData.append("uuid","check")

      const insertNewNode = (question) =>{
        props.insertNewNode(question,props.path)
        closeModel()
         
      }
      formData.append("document", newQuestion.document ? newQuestion.document: "")
      formData.append("question", newQuestion.question)
      formData.append("answer", newQuestion.answer)
      formData.append("keywords", keywor)
      formData.append("url", newQuestion.url)
      formData.append("prev_context", newQuestion.prev_context)
      formData.append("texturl", newQuestion.textUrl)
      formData.append("leaf_node", newQuestion.leaf_node)
      formData.append("inputs_required", newQuestion.inputs_required)
      formData.append("company_name", newQuestion.company_name)
      dispatch(CreateWorkflowContext(formData,insertNewNode))
    // console.log(props.path)
   
    }
    
  }
  const handleClose = ()=>{
    dispatch({type: "GET_WORKFLOWEDIT_RESET"})
    closeModel()
  }

  const handleCopy = (e) =>{
    if(!answer){
      setError("Please Fill Out The Answer Field")
     }else if(!question){
       setError("Please Fill Out The Question Field")
     }else if(!keywords){
      setError("Please Fill Out The Keywords Field")
  
     }
      e.preventDefault()
      const newQuestion = {
        question,
        prev_context: EditWorkflow.prev_context ? EditWorkflow.prev_context : "",
        leaf_node: false,
        inputs_required: false,
         answer,
         company_name: "linkintime",
         document: file,
         url,
         keywords
      }
     
      if(answer && question && keywords){
        let keywor = keywords
        if(!keywords.includes(",")){
         keywor = keywords+","
        }
        let formData = new FormData();  
        // formData.append("uuid","check")
        formData.append("document", file ? file : doc ? doc : "")
        formData.append("question", newQuestion.question)
        formData.append("answer", newQuestion.answer)
        formData.append("keywords", keywor)
        formData.append("url", newQuestion.url)
        formData.append("prev_context", newQuestion.prev_context)
        // formData.append("texturl", newQuestion.textUrl)
        formData.append("leaf_node", newQuestion.leaf_node)
        formData.append("inputs_required", newQuestion.inputs_required)
        formData.append("company_name", newQuestion.company_name)

        const insetcopyNode = (question) =>{
          console.log(props.path)
          props.insertNewNode(question,props.path)
        }

        // insetcopyNode()

       
        dispatch(CopyContextWorkflow(formData,handleClose,insetcopyNode))
      
      
      }
      
  }


  
const handleChangeSelection = (e) =>{
  // 
  if(e){
    if(e.index){
    setCharector(e.index)
    }
  }
  // console.log(e)
}

  return ReactDOM.createPortal(
    (
      <div className="model">
        <form onSubmit={node  ? copy ? handleCopy :handleUpdate : handleCreateQuestion}>

       
        <span onClick={handleClose} className="model-close">
          +
        </span>
        <div className="model-container">
        {error && <p className="model-container-error">{error}</p>}
          <label>Question <span>*</span></label>
          <input
            type="text"
            value={question}
            placeholder="Your Question"
            onChange={(e) =>{setQuestion(e.target.value); setError(null)}}
          />
          <div className="model-container-answer">
          <p id="text" className="model-container-textarea-error">WARNING! Caps lock is ON.</p>
          <label>Answer <span>*</span></label>
          
          <EditorToolbar/>
          <ReactQuill
            theme="snow"
            className="model-container-textarea"
            value={answer}
            onChangeSelection={handleChangeSelection}
            modules={modules}
            formats={formats}
            onChange={(e)=>{answerHandler(e); setError(null)}}
            ref={answerRef}
          />
          <span className="model-container-textarea-charector">Position: {charecter}</span>
          </div>
          <label>Document</label>

          <div class="model-container-upload">
           {!doc ?
           <>
           
           <input
              type="file"
              id="upload"
              onChange={handleFilesUpload}
              placeholder="Document"
            />
            {file ? (
              <div>
               
                <span>{file.name}</span>
              </div>
            ) : <span></span>}
            <label class="model-container-upload-button" for="upload">
              Upload File
            </label>
            
            </> : 
            <div className="model-container-upload-preDoc">
            <span  className="model-container-upload-preDoc-docname">{doc}</span>
            <img src={DeleteSVG} alt="delete doc" onClick={()=> setDocument(null)}/>
            </div>
            }
           
          </div>
          <label>Keywords <span>*</span></label>
          <input
            type="text"
            value={keywords}
            onChange={(e) => {setKeywords(e.target.value); setError(null)}}
            placeholder="Keywords : KYC, know your customer"
          />
          <label>Url</label>
          <input
            type="text"
            value={url}
            onChange={(e) => seturl(e.target.value)}
            placeholder="URL: www.google.com"
          />
       

          {node  ? 
          copy ? <button className="model-btn" >Save This Copy</button>  :<button className="model-btn" >Update</button>
            :  <button className="model-btn">Save</button>
          }
        </div>
        </form>
      </div>
    ),
    document.getElementById("model")
  );
};


export default Model;
