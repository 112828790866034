import React from 'react'
import LogoImg from "../assets/img/logo-dark.png";
import "../../styles/logo.scss"
const Logo = () => {
  return (
    <div className='logo'>
        <img src="https://idia-admin.linkintime.co.in/logo/logo-dark.png" alt="linkintime logo"/>
    </div>
  )
}

export default Logo