import { errorHandler, getApiCall } from "./UtilsActions";
import axios from "axios";
import url  from '../webconfig.json'
export const GetDeletedLogsContext = (URLaddres) => async (dispatch, getState) => {
    console.log("here")
    try{

        dispatch({ 
            type: "GET_DELETEDLOGS_REQUEST"
        })

        // const {
        //     userLogin: { userInfo },
        // } = getState()
        // // console.log(userInfo.access)
        // const config = {
        //     headers:{
        //         'Content-type': 'application/json',
        //         'Authorization': `Bearer ${userInfo.access}`
        //     }
        // };
        // if(URLaddres){
        //     const {data} = await axios.get(
        //         URLaddres.includes("idia-backend.linkintime") ? URLaddres.replace("http:", "https:") :URLaddres,config
        //         )
        //         dispatch({
        //             type: "GET_DELETEDLOGS_SUCCESS",
        //             payload: data
        //         })
        // }else{
        //     const {data} = await axios.get(
        //         ` ${url.proxy}/account/admin-trash/`,config
        //         )
        //         console.log(data)
        //         dispatch({
        //             type: "GET_DELETEDLOGS_SUCCESS",
        //             payload: data
        //         })
        // }
       
            // console.log(data)
       

        // dispatch(users())

        const URL = `${url.proxy}/account/admin-trash/`
        const data = await getApiCall(URL, URLaddres,null)
        dispatch({ type: "GET_DELETEDLOGS_SUCCESS", payload: data })

    }catch(error){
        
        errorHandler(dispatch, error, "GET_DELETEDLOGS_FAIL")
    }
};

export const SearchDeltedLogs = (RequestData) => async (dispatch, getState) => {
    console.log("here")
    try{

        dispatch({ 
            type: "GET_DELETEDLOGS_REQUEST"
        })

        const {
            userLogin: { userInfo },
        } = getState()
        // console.log(userInfo.access)
        const config = {
            headers:{
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.access}`
            }
        };
        
            const {data} = await axios.post(
                ` ${url.proxy}/account/admin-trash-keyword/`,RequestData,config
                )
                console.log(data)
                dispatch({
                    type: "GET_DELETEDLOGS_SUCCESS",
                    payload: data
                })
        
       
            // console.log(data)
       

        // dispatch(users())

    }catch(error){
        errorHandler(dispatch, error, "GET_DELETEDLOGS_FAIL")

        // dispatch({
        //     type: "GET_DELETEDLOGS_FAIL",
        //     payload: (error.response && error.response.data.detail
        //         ? error.response.data.detail 
        //         : error.message)
        // })
    }
};

export const RestoreDeletedLogsContext = (id,type) => async (dispatch, getState) => {
    // console.log("here")
    try{

        const {
            userLogin: { userInfo },
        } = getState()
        console.log(userInfo.access)
        const config = {
            headers:{
                'Content-type': 'application/json',
                'Authorization': `Bearer ${userInfo.access}`
            }
        };
       
            const {data} = await axios.post(
                ` ${url.proxy}/account/admin-trash/`,{id},config
                )
                if(typeof(data[0]) === "string"){
                    dispatch({type: "CREATE_NOTIFICATION", payload: {message: data[0], type: "success"}})
                }
                // dispatch({
                //     type: "GET_DELETEDLOGS_SUCCESS",
                //     payload: data
                // })

                console.log(data)
                dispatch(GetDeletedLogsContext(type))
       
            // console.log(data)
       

        // dispatch(users())

    }catch(error){
        errorHandler(dispatch, error, "GET_DELETEDLOGS_FAIL")
    }
};