const initailState = {
    logs:null
}

export const UserLogsReducers = (state=initailState, action) => {

    switch (action.type) {
        case "GET_USERLOGS_REQUEST": 
            return { loading: true}

        case "GET_USERLOGS_SUCCESS": 
            return {loading: false, logs: action.payload}

        case "GET_USERLOGS_FAIL":
            return {loading: false, error: action.payload}

        case "GET_USERLOGS_RESET":
            return {}

        default:
            return state
    }
}