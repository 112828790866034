import axios from 'axios';
import { logout } from './UserLoginActions'

export const errorHandler = (dispatch, error, dispatch_type) => {

    if(error.response.status === 401){
        dispatch({
            type: dispatch_type,
            payload: (error.response && error.response.data.detail
                ? error.response.data.detail 
                : error.message)
        })
      
        dispatch({type: "CREATE_NOTIFICATION", payload: {message: "User authentication failed please log in Again.", type: "error"}})
        
        setTimeout(() => {
            dispatch(logout())
          }, 5000);

    }
    else{
        // console.log(typeof(error.response.data))
        if(typeof(error.response.data) === "string"){
            
            dispatch({type: "CREATE_NOTIFICATION", payload: {message: error.response.data.length > 150 ? "Something went wrong please try again later." : error.response.data, type: "error"}})

        }else{
            dispatch({type: "CREATE_NOTIFICATION", payload: {message: "Something went wrong please try again later.", type: "error"}})

        }
        dispatch({
            type: dispatch_type,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail 
                : error.message
        })
    }

}

export const getApiCall = async (defaultUrl,type,date) =>{
    const userDetails = JSON.parse(localStorage.getItem("userInfo"))
    const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userDetails.access}`,
        },
      };
      if (date) {
        defaultUrl = defaultUrl + `?${date}`;
      }

      if (type) {
        if(!type.includes("http")){
        defaultUrl = date ?  defaultUrl + `&order_by=${type}` : defaultUrl + `?order_by=${type}`
        }else{
          defaultUrl = type
        }
      }

      const { data } = await axios.get(defaultUrl.includes("idia-backend.linkintime") ? defaultUrl.replace("http:", "https:") :defaultUrl, config);
    
      return data
}

export const FileDownload = (defaultUrl,FileName)=>{
    const userDetails = JSON.parse(localStorage.getItem("userInfo"))
//   console.log(config)
    fetch(defaultUrl, {
        method: 'POST',
        headers: {
            "Content-type": "application/vnd.ms-excel",
            Authorization: `Bearer ${userDetails.access}`,
          },
      })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        console.log(blob,"test")
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          FileName,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
}


export const FileDownloadGet = (defaultUrl,FileName)=>{
  const userDetails = JSON.parse(localStorage.getItem("userInfo"))
//   console.log(config)
  fetch(defaultUrl, {
      method: 'GET',
      headers: {
          "Content-type": "application/vnd.ms-excel",
          Authorization: `Bearer ${userDetails.access}`,
        },
    })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      console.log(blob,"test")
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        FileName,
      );
  
      // Append to html link element page
      document.body.appendChild(link);
  
      // Start download
      link.click();
  
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
}