import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import  DatePicker  from 'react-datepicker'
import SortSvg from "../assets/svg/SortSvg.svg";
import UpArrow from "../assets/svg/arrowUp.svg";
import DownArrow from "../assets/svg/arrowDown.svg";
import EditSvg from "../assets/svg/EditTextsvg.svg";
import CheckSvg from "../assets/svg/CheckSVG.svg";
import RemoveSvg from "../assets/svg/RemoveSvg.svg";
import UncheckedSvg from "../assets/svg/Unchecked.svg";
import ExportIcon from "../assets/svg/export.svg";
import SearchSvg from "../assets/svg/search.svg";
import ViewIcon from "../assets/svg/ExpandSVG.svg"
import "./Table.scss"
import moment from 'moment';


const Table = ({
    HeadingOption,
    title,
    DataOption,
    current,
    next,
    previous,
    handlePaginateAction,
    handleStatusAction,
    EnableDatePicker,
    EnableCSVExport,
    EnableFileUpload,
    uploadOption,
    EnableSearch,
    handleRestore,
    handleSearchAction,
    hanldeCSVFileAction,
    handleFileUploadAction,
    EnableTitleInfo,
    showTitleDate,
    titleinfo,
    handleEditAction,
    EnableSimpleBtn,
    btnOption
}) => {


  const [ dateRange, setDateRange ] = useState([null, null]);
  const [sortType, setSortType] = useState("-created_at");
  const [tabledata, setUnttagedDate] = useState();
  const [search, setSearch] = useState("")
  const [showbtn, setShowBtn] = useState(true);
  const [editindex, setEditIndex] = useState("");
  const [inputValue, setinputValue] = useState({})
  const dispatch = useDispatch()

  const [ startDate, endDate ]   = dateRange;

  const onSort = (type)=>{
    const isDateThere = tabledata ? tabledata : null
   
    let order = sortType
    // if(EnableFileUpload){
      // console.log(sortType)
      // console.log(sortType.includes("-"))
      if(sortType && !sortType.includes("-")){

        type === "date" ? order = "-created_at" :  order ="-"+type

        setSortType(order)
       
      }else{
        type === "date" ? order = "created_at" :  order = type

        setSortType(order)
      }
    // }else{
    //   setSortType(type)
    // }
    console.log(order)
    EnableFileUpload? handlePaginateAction(null,order) :dispatch(handlePaginateAction(order,isDateThere))
  }
  const handleQuestionStatus = (status,id) =>{
    // const resolved = status === null ? true : status? false : true
    const resolved = status === null ? true : status === false ? null : false
    const isDateThere = tabledata ? tabledata : null
    const data = {id,resolved}  
    // console.log(sortType,next)
    dispatch(handleStatusAction(sortType,isDateThere,data))
  }
  const handleNext = () => { 
      setSortType(next)
      EnableFileUpload? handlePaginateAction(next) :dispatch(handlePaginateAction(next));
    };
  const handlePrev = () => {
      setSortType(previous)
      EnableFileUpload? handlePaginateAction(previous) :dispatch(handlePaginateAction(previous));
    };
  const CSVFileHandler = () =>{
    // console.log(startDate, endDate)
    
    let data = null
    let date = null
    if(tabledata){
      let start = startDate ? moment(startDate).format('YYYY-MM-DD') : null
      let end = endDate ? moment(endDate).format('YYYY-MM-DD')  : null
      date = {start, end}
      data = `start_date=${start}&end_date=${end}`
    }
    // console.log(data)
   dispatch(hanldeCSVFileAction(data, date))
  }
  const TimeSelectHandler = (startDate, endDate) => {
    let start = startDate ? moment(startDate).format('YYYY-MM-DD') : null
    let end = endDate ? moment(endDate).format('YYYY-MM-DD')  : null
    // setOnSubmit(true)
    if(start && end){
      // console.log(start, end)
      setSortType("")
      const date = `start_date=${start}&end_date=${end}`
      setUnttagedDate(date)
      dispatch(handlePaginateAction(null,date));
    }else if(!start && !end){
      setUnttagedDate()
      setSortType("")
      dispatch(handlePaginateAction(null))
    }
  }
const handleOnchange=(e)=>{
    e.preventDefault()
    setSearch(e.target.value)
    if(!e.target.value){
        EnableFileUpload ? handlePaginateAction() : dispatch(handlePaginateAction())
    }

}
const onSearchHandler = () =>{
    const RequestData = {
        key: search
    }
    EnableFileUpload ? handleSearchAction(RequestData) :dispatch(handleSearchAction(RequestData))
}
const keyCode = (e) =>{
  if(e.keyCode === 13 && search.trim()){

    onSearchHandler()
  }
}
const handleFileUpload = (file)=>{
  // console.log(file.target.value)
  var validExts = [".xlsx", ".xls"];
  var fileExt = file.target.value;
  fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
  if (validExts.indexOf(fileExt) < 0) {
    dispatch({type: "CREATE_NOTIFICATION", payload: {message: "Please upload an excel sheet.", type: "error"}})
    // return false;
  }
  else {
    // console.log(fileExt, file.target.files[0])
    setShowBtn(false)
    const formData = new FormData(); 
    formData.append( 
      "file", 
     file.target.files[0], 
     file.target.files[0].name
    );
   handleFileUploadAction(formData,setShowBtn)
  }
}
const handleArrowSvg = (name) =>{
  let svg;

  if(sortType.includes(name.toLowerCase())){
    sortType.includes("-") ? svg = UpArrow : svg = DownArrow
  }
  else if(sortType.includes("created_at") && name.toLowerCase() === "date"){
    sortType.includes("-") ? svg = UpArrow  : svg =  DownArrow
  }
  else if(!sortType && name.toLowerCase() === "date"){
    svg = UpArrow
  }
  else{
    svg = SortSvg
  }
// if(name.toLowerCase() === "date"){
//   sortType.includes("-") ? svg = UpArrow : svg = DownArrow
// }else{
//   sortType.includes("-") ? svg = UpArrow : svg = DownArrow
// }
return svg


}


const handleEditable = (index,data)=>{
  setinputValue({data})
  setEditIndex(index)
  // console.log(data)
}

const handleSubmit=(e)=>{
  e.preventDefault()
  handleEditAction(inputValue,setEditIndex)

  
  // console.log(e.target.Branch)
}

const handleOnChange = (title,value) =>{
  const oldinput = structuredClone(inputValue)
  oldinput[title] = value
  // console.log(oldinput)
  setinputValue(oldinput)
}
  return (
    <>
    <div className="table-heading">
        {/* {console.log(DataOption)} */}
       
      <div className='table-heading-container'>
        <span>{title}</span> 
{/* start --- date picker with csv export */}
    { EnableDatePicker && 
            <div className="table-heading-date">
                <div className="table-heading-date-picker">
                    <DatePicker
                        dateFormat="yyyy-MM-dd"
                        selected={startDate}
                        selectsRange={true}
                        todayButton="Today"
                        startDate={startDate}
                        endDate={endDate}   
                        maxDate={new Date()}
                        onChange={(update) => {
                            setDateRange(update);
                            TimeSelectHandler(update[0],update[1])
                        }}
                        isClearable={true}
                        tabIndex={1}
                        placeholderText="Select date range..."/> 
                    </div>
              
            </div>}

            {EnableCSVExport && (DataOption.length !== 0) &&
                  <span onClick={CSVFileHandler} className={`table-heading-date-export ${(EnableDatePicker && startDate !== null && endDate !== null && Math.ceil((Date(endDate)- Date(startDate)) / (1000 * 60 * 60 * 24))) <= 95 ? "table-heading-date-export-disabled" : ""}`} title={`${EnableDatePicker ? "Select max 3 months" : "Export To Excel"}`}>
                    Export <img src={ExportIcon} alt="Export To CSV" />
                  </span>}
{/* End --- date picker with csv export */}
        
      </div>

      {EnableTitleInfo && 
        <div className='table-heading-container-titleinfo'>
          { showTitleDate &&<div className='table-heading-container-titleinfo-date'>
            Analytics 
            
             {`${startDate && endDate ? " from "+ new Date(startDate).toLocaleDateString().split("/").join("-") 
                    + " to " + 
                    new Date(endDate).toLocaleDateString().split("/").join("-")
                    : " for today"}`} 
          
          </div>}

          <div className='table-heading-container-titleinfo-list'>
          {titleinfo.map((e)=>
          <span className='table-heading-container-titleinfo-item'>
              <span className='table-heading-container-titleinfo-item-title'>
                {e.title} 
              </span> 
              <span className='table-heading-container-titleinfo-value'> {e.value}</span>
          </span>)}
          </div>
        </div>}

        <div className='table-heading-SearchWithBtnsContainer'>
              {EnableSearch && 
                  <div className="table-input-container">
                          <img src={SearchSvg} alt="search icon" />
                          <input placeholder='search' onKeyDown={keyCode} value={search} onChange={handleOnchange}/><button onClick={onSearchHandler} title="Search" disabled={search.length < 1}>Search</button>
                  </div>}
              {(EnableSimpleBtn || EnableFileUpload) && 
                <div className='table-heading-btn'>
                  {EnableSimpleBtn && 
                    <span onClick={btnOption.handler} className={`table-heading-date-export`} title={btnOption.title}>
                      {btnOption.name}
                      <img src={btnOption.icon} alt={btnOption.title} />
                </span>}
              {EnableFileUpload && 
                  <>
                    <input id="table-file-upload" accept='.xlsx' className='table-file-upload' onChange={handleFileUpload} type="file"/>
                    <label for={showbtn && "table-file-upload"}>
                    <span className={`table-heading-date-export ${!showbtn ? "table-heading-date-export-disabled" : ""}`} title={uploadOption.title}>{uploadOption.name}<img src={uploadOption.icon} alt={uploadOption.title} /></span>
                    </label>
                </>}
              </div>}
        </div>



      </div>
      <div className="table-container">
         {/* start --- Head Data */}
         {/* headingOption = [{name:"heading 1", type:"sort": title: "sort by this" sortFunction: handleSort}] */}
        <div className="table-container-head">
         {HeadingOption.map((e,el)=>{
            return (<span className={`table-container-head-sort ${e.class ? "table-"+e.class: ""}`} key={el}> 
                {e.name} 
                {e.type === "sort" && 
                
                 <img src={handleArrowSvg(e.name)} onClick={()=>onSort(e.name.toLowerCase())} title={e.title} alt="sort by question"/>}
            
             </span>)
         })}

        </div>
        {/* End --- Head Data */}

            {/* start --- Table data with map Data */}
            {/* dataoption = [{name: }] */}         
            {DataOption.length ? DataOption.map((e,el)=>{
             const id = e[0].id
            //  console.log(id)
            return <div className="table-container-data">
                {
                // check if edittabse is set with id if yes then render input tags
                editindex === id? 
                <form className="table-container-data-form" onSubmit={handleSubmit}>
                {e.map((e,colindex)=>{

                  const titleName = HeadingOption[colindex].name.toLowerCase()
                  
                 if(e.editType === "dropdown"){
                  
                  return <span><select  onChange={(e)=>handleOnChange(titleName, e.target.value)}>
                    <option value="none" selected disabled hidden>{e.value}</option>
                    {e.dropdown.map((e)=>{
                      return<option value={e.val}>{e.type}</option>
                    })}
                  </select></span>
                 }
                 else if(e.type === "editable"){
                  return  <span className={`table-edit-name table-${e.class && e.class}`}>
                    <input required type="text" defaultValue={e.value} maxLength="12" onChange={(e)=>handleOnChange(titleName, e.target.value)} />
                    <button className='table-edit-btn' title='update'>Update</button>
                </span>}
                 else if(e.editType === "input"){
                  return <span className={`table-${e.class && e.class}`}><input required type="text" defaultValue={e.value} onChange={(e)=>handleOnChange(titleName, e.target.value)}/></span>
                 }
                 else{
                  return <span className={`table-${e.class && e.class}`}>{e.value}</span>
                 }
                }) }
                </form>
                // normal data map without edit 
                :e.map((e)=>{
                if(e.type === "click"){
                    return (
                    <>
                    <span className={`table-container-data-context-table ${e.class && e.class}`}>
                          <span className="table-container-data-context-table-icon" onClick={()=>handleQuestionStatus(e.resolved, e.id)}>
                        <img  title={e.resolved === null ? "Resolve" : e.resolved ? null : "Resolve"} src={e.resolved === null ? CheckSvg : e.resolved ? RemoveSvg : UncheckedSvg} alt="Resolve"/>
                        </span> 
                             <span className={`table-container-data-context-table-text`}>{e.value}</span>
                        </span>
                      </>
                    )
                }else if(e.type === "number_list"){
                  return(
                    <span className={`${e.class ? "table-"+e.class: ""}`}>
                    <ol className='table-number-list'>
                      {e.value.map((e)=><li>{e}</li>)}
                    </ol>
                    </span>
                  )
                }else if(e.type === "status"){
                   return <span className={`table-container-data-context-table-status table-container-data-context-table-status-${e.resolved === null ? "" : e.resolved ? "resolved" : "removed"} `}>
                 {e.resolved === null ? "Pending" : e.resolved ? "Resolved" : "Removed"}
                 </span>
                }else if(e.type === "restore"){
                  return(
                    <span className='table-restore-username'>
                      <span>{e.value}</span>
                      <span className='table-restore-btn' title='Restore' onClick={()=>handleRestore(e.id,sortType)}>+</span>
                    </span>
                  )
                }else if(e.type === "action"){
                  // {console.log(e.value[0])}
                 return (
                  <span className={`table-${e.class && e.class}`}>
                  <p className="table-data-action-text">
                  <p className={`table-data-action-text-type table-data-action-text-type-${e.value[0].toLowerCase()}`}>
                   {e.value[0]} </p>  
                   {e.value[1]}
                   </p>
               
                 
                { !(e.value.includes("Deleted") || e.value.includes("Restored")) &&<span className="table-data-action-btn">
                   <img
                     src={ViewIcon}
                     onClick={() => e.func(e.data)}
                     alt="view"
                     title="View More"
                   />
                 </span>}
                 </span>)
                }else if(e.type === "editable"){
                  return(
                    <span className={`table-edit-username table-${e.class && e.class}`} >
                    <span>{e.value}</span>
                    <span className='table-edit-btn' title='Edit Client' onClick={()=>handleEditable(id,e.data)}><img src={EditSvg} alt="edit"/></span>
                  </span>
                  )
                }
                
                else{
                    return <span className={`table-${e.class && e.class}`}>{e.value}</span>
                }
               })
               
               }
              </div> })
              : <div className="table-container-empty">No Records Found.</div> }
               
            {/* End --- Table data with map Data */}


      </div>
      {/* start --- Page No Next and Prev */}
      {DataOption.length !== 0 && (
        <div className="table-page">
            Page: <span className="table-page-no">{current}</span>
          </div>
        )}
      <div className="table-btns">
      {previous && (
          <div onClick={handlePrev}>
            Prev<span>{`<`}</span>
          </div>
        )}
        {next && (
          <div onClick={handleNext}>
            <span>{`>`}</span>Next
          </div>
        )}
      </div>

       {/* End --- Page No Next and Prev */}
       {/* <EditTableModel/> */}
    </>
  )
}

export default Table